(function() {
	'use strict';
	angular.module( 'roi.app' ).factory( 'CatalogsService', CatalogsService );

	CatalogsService.$inject = ['$http', '$location', '$q', '$log', 'APIConfigService'];

	function CatalogsService($http, $location, $q, $log, APIConfigService) {

		return {
			getCatalogs: getCatalogs,
			addImages: addImages,
			//updateImages: updateImages,
			//deleteImages: deleteImages,
		};

		function getCatalogs() {
			let url = APIConfigService.url( 'catalogs' );
			return $http.get( url )
			.then( onComplete )
			.catch( onFailed );

			function onComplete(response) {
				return response.data;
			}

			function onFailed(error) {
				console.log( 'XHR Failed for getCatalogs.' + error );
			}
		}

		/*each of image of images:{
			"filename":"8acc20c0-96a5-40d6-988e-dbe80be6cb36.jpg", - from file upload
			"name":"Catalog image flower",
			"desc": "Description of uploaded file",
			"price":16,
			"directdl": true
		}*/
		function addImages(userId, images) {
			let url = APIConfigService.url( 'catalogs', userId );
			return $http.post( url, images )
			.then( onComplete )
			.catch( onFailed );

			function onComplete(response) {
				return response.data;
			}

			function onFailed(error) {
				console.log( 'XHR Failed for addImages.' + error );
			}
		}

		/*function updateImages(images) {
			let url = APIConfigService.url( 'catalogs' );
			return $http.put( url, images )
			.then( onComplete )
			.catch( onFailed );

			function onComplete(response) {
				return response.data;
			}

			function onFailed(error) {
				console.log( 'XHR Failed for updateImages.' + error );
			}
		}

		function deleteImages(images) {
			let url = APIConfigService.url( 'catalogs' );
			return $http.delete( url, images )
			.then( onComplete )
			.catch( onFailed );

			function onComplete(response) {
				return response.data;
			}

			function onFailed(error) {
				console.log( 'XHR Failed for deleteImages.' + error );
			}
		}*/
	}
})();
