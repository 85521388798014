(function () {
	'use strict';
	angular.module('roi.jobtypes').controller('JobTypeListController', JobTypeListController);

	JobTypeListController.$inject = ['$scope', '$state', '$uibModal', 'JobTypeService', 'StaticDataService', 'toastr'];

	function JobTypeListController($scope, $state, $uibModal, JobTypeService, StaticDataService, toastr) {
		let self = this;
		self.selectedJobType = null;

		self.editJobType = editJobType;
		self.deleteJobType = deleteJobType;
		self.configureWorkFlow = configureWorkFlow;
		self.confirm = confirm;
		self.cancel = cancel;

		init();

		function init() {
			JobTypeService.getJobTypes().then(function(jobtypes){
				self.jobtypes = angular.copy(jobtypes);
				self.jobtypeTable = angular.copy(jobtypes);
			});
		}

		function editJobType(jobtypeId) {
			$state.go('app.jobtypes.jobtype', { jobtypeId: jobtypeId });
		}
		function configureWorkFlow(jobtypeId) {
			$state.go('app.jobtypes.workflow', { jobtypeId: jobtypeId });
		}

		function deleteJobType(jobtype) {
			self.selectedJobType = jobtype;
			self.modalInstance = $uibModal.open({
				animation: true,
				scope: $scope,
				ariaLabelledBy: 'modal-title',
				ariaDescribedBy: 'modal-body',
				templateUrl: 'deletejobtype.html',
				size: 'sm',
				resolve: {}
			});
			self.modalInstance.result.then(function (response) {
				return JobTypeService.deleteJobType(self.selectedJobType.jobtypeId).then(function (response) {
					if (response.data === 'ok') {
						toastr.success('JobType has been deleted', 'Success');
						removeItem(jobtype);
					} else {
						toastr.error('JobType has not been deleted', 'Error');
					}
					self.selectedJobType = null;
				}).catch(function (error) {
					self.selectedJobType = null;
					console.error(error);
				});
			}, function () {
				self.selectedJobType = null;
			});
		}

		function confirm() {
			self.modalInstance.close();
		}

		function cancel() {
			self.modalInstance.dismiss();
		}

		// table functions
		function removeItem(row) {
			self.jobtypes = _.filter(self.jobtypes, function (o) {
				return o.jobtypeId !== row.jobtypeId;
			});
		}

	}
})();