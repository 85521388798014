(function () {
	'use strict';
	angular.module('roi.statuses')
	.config(['$stateProvider', function ($stateProvider) {
		$stateProvider.state('app.statuses', {
			url: '/statuses',
			abstract: true,
			ncyBreadcrumb: {
				skip: true
			},
			template: '<ui-view></ui-view>'
		});
		$stateProvider.state('app.statuses.list', {
			url: '/list',
			ncyBreadcrumb: {
				label: 'Statuses'
			},
			controller: 'StatusListController',
			controllerAs: 'tc',
			templateUrl: './js/modules/statuses/status-list.html'
		});
		$stateProvider.state('app.statuses.status', {
			url: '/status/:statusId',
			ncyBreadcrumb: {
				parent: 'app.statuses.list',
				label: 'Status'
			},
			controller: 'StatusController',
			controllerAs: 'tc',
			templateUrl: './js/modules/statuses/status.html'
		});
		$stateProvider.state('app.statuses.new', {
			url: '/status/new',
			ncyBreadcrumb: {
				parent: 'app.statuses.list',
				label: 'Add New Status'
			},
			controller: 'NewStatusController',
			controllerAs: 'tc',
			templateUrl: './js/modules/statuses/status-new.html'
		});
	}]);
})();