(function () {
	'use strict';
	angular.module('roi.usergroups').controller('NewUserGroupController', NewUserGroupController);

	NewUserGroupController.$inject = ['$scope', '$state', 'UserGroupService', 'StaticDataService', 'toastr'];

	function NewUserGroupController($scope, $state, UserGroupService, StaticDataService, toastr) {
		let self = this;
		self.usergroup = null;
		self.staticData = StaticDataService.getStaticData();

		self.modelform = {};
		self.model = {
			usergroup: null
		};

		self.submitChanges = submitChanges;

		function submitChanges() {
			let submittedData = angular.copy(self.model.usergroup);
			addUserGroup(submittedData);
		}

		function addUserGroup(userGroupData) {
			return UserGroupService.addUserGroup(userGroupData).then(function (response) {
				console.log('User Group has been added ', userGroupData);
				if (response.err === false) {
					toastr.success('User Group data have been added', 'Success');
					$state.go('app.usergroups.list');
				} else {
					toastr.error('User Group data have not been added', 'Error');
				}
			}).catch(function (error) {
				console.error(error);
			});
		}
	}
})();
