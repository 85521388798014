(function () {
	'use strict';
	angular.module('roi.usergroups')
	.config(['$stateProvider', function ($stateProvider) {
		$stateProvider.state('app.usergroups', {
			url: '/usergroups',
			abstract: true,
			ncyBreadcrumb: {
				skip: true
			},
			template: '<ui-view></ui-view>'
		});
		$stateProvider.state('app.usergroups.list', {
			url: '/list',
			ncyBreadcrumb: {
				label: 'User Groups'
			},
			controller: 'UserGroupListController',
			controllerAs: 'ugc',
			templateUrl: './js/modules/usergroups/usergroup-list.html'
		});
		$stateProvider.state('app.usergroups.group', {
			url: '/usergroup/:ugroupId',
			ncyBreadcrumb: {
				parent: 'app.usergroups.list',
				label: 'Group'
			},
			controller: 'UserGroupController',
			controllerAs: 'ugc',
			templateUrl: './js/modules/usergroups/usergroup.html'
		});
		$stateProvider.state('app.usergroups.new', {
			url: '/usergroup/new',
			ncyBreadcrumb: {
				parent: 'app.usergroups.list',
				label: 'Add New Group'
			},
			controller: 'NewUserGroupController',
			controllerAs: 'ugc',
			templateUrl: './js/modules/usergroups/usergroup-new.html'
		});
	}]);
})();