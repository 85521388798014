(function () {
	'use strict';
	angular.module('roi.statuses').controller('StatusController', StatusController);

	StatusController.$inject = ['$scope', '$state', '$stateParams', 'StatusService', 'StaticDataService', 'Utilities', 'toastr'];

	function StatusController($scope, $state, $stateParams, StatusService, StaticDataService, Utilities, toastr) {
		let self = this;
		self.status = null;
		self.jobtypes = [];
		let statuses = [];
		self.modelform = {};
		self.model = {
			status: null,
			disableEdit: false
		};

		self.discardChanges = discardChanges;
		self.submitChanges = submitChanges;
		self.someSelected = Utilities.someSelected;
		self.isCheckboxSelected = Utilities.isCheckboxSelected;
		self.modelFilter = Utilities.modelFilter;

		init();

		function init() {
			StaticDataService.getStaticData().then(function(listData){
				self.jobtypes = listData.jobtypes;
				statuses = listData.statuses;
				getStatus($stateParams.statusId);
			});
		}

		function getStatus(jobstatusId) {
			let status = _.find(statuses, { jobstatusId: parseInt(jobstatusId) });
			status.jobtype = Utilities.arrayToObjectModel(status.jobtypeIds);
			status.enabled = !!status.enabled;
			self.model.disableEdit = !status.enabled;
			self.status = angular.copy(status);
			self.model.status = angular.copy(status);
		}

		function submitChanges() {
			let submittedData = angular.copy(self.model.status);
			submittedData.enabled = !self.model.disableEdit;
			submittedData.jobtypeIds = _.map(_.keys(self.model.status.jobtype), _.parseInt);
			delete submittedData.jobtype;
			let statusData = Utilities.filterChanges(self.status, submittedData);
			if (_.isEmpty(statusData)) {
				toastr.warning('No status data have been changed', 'Warning');
				return false;
			}
			statusData.jobstatusId = self.model.status.jobstatusId;
			editStatus(statusData);
		}

		function discardChanges() {
			self.model.status = angular.copy(self.status);
		}

		function editStatus(statusData) {
			return StatusService.editStatus(statusData).then(function (response) {
				console.log('Status has been changed to ', statusData);
				if (response.err === false) {
					toastr.success('Status data have been changed', 'Success');
					$state.go('app.statuses.list');
				} else {
					toastr.error('Status data have not been changed', 'Error');
				}
			}).catch(function (error) {
				console.error(error);
			});
		}
	}
})();
