(function () {
	'use strict';
	angular.module('roi.usergroups').controller('UserGroupListController', UserGroupListController);

	UserGroupListController.$inject = ['$scope', '$state', '$uibModal', 'UserGroupService', 'toastr'];

	function UserGroupListController($scope, $state, $uibModal, UserGroupService, toastr) {
		let self = this;
		self.usergroups = [];
		self.userGroupTable = [];
		
		self.deleteUserGroup = deleteUserGroup;
		self.editUserGroup = editUserGroup;
		
		init();

		function init() {
			UserGroupService.getUserGroups().then(function(groups){
				self.usergroups = angular.copy(groups);
				self.userGroupTable = angular.copy(groups);
			});
		}
		
		function editUserGroup(group) {
			$state.go('app.usergroups.group', { ugroupId: group.ugroupId });
		}
		
		// table functions
		function deleteUserGroup(group) {
			if(group.ugroup <= 4)
				return;
			UserGroupService.deleteUserGroup(group.ugroupId).then(function(){
				let index = self.usergroups.indexOf(group);
				if (index !== -1) {
					self.usergroups.splice(index, 1);
				}
				index = self.userGroupTable.indexOf(group);
				if (index !== -1) {
					self.userGroupTable.splice(index, 1);
				}
			})
			.catch(toastr.error);
		}
	}
})();
