(function () {
	'use strict';
	angular.module('roi.actions').controller('ActionListController', ActionListController);

	ActionListController.$inject = ['$scope', '$state', '$uibModal', 'JobActionService', 'StaticDataService', 'toastr'];

	function ActionListController($scope, $state, $uibModal, JobActionService, StaticDataService, toastr) {
		let self = this;
		self.selectedAction = null;

		self.editAction = editAction;
		self.deleteAction = deleteAction;
		self.confirm = confirm;
		self.cancel = cancel;

		init();

		function init() {
			JobActionService.getActions().then(function(actions){
				self.actions = angular.copy(actions);
				self.actionTable = angular.copy(actions);
			})
		}

		function editAction(jobactionId) {
			$state.go('app.actions.action', { actionId: jobactionId });
		}

		function deleteAction(action) {
			self.selectedAction = action;
			self.modalInstance = $uibModal.open({
				animation: true,
				scope: $scope,
				ariaLabelledBy: 'modal-title',
				ariaDescribedBy: 'modal-body',
				templateUrl: 'deleteaction.html',
				size: 'sm',
				resolve: {}
			});
			self.modalInstance.result.then(function (response) {
				return JobActionService.deleteAction(self.selectedAction.jobactionId).then(function (response) {
					if (response.err === false) {
						toastr.success('Action has been deleted', 'Success');
						removeItem(action);
					} else {
						toastr.error('Action has not been deleted', 'Error');
					}
					self.selectedAction = null;
				}).catch(function (error) {
					self.selectedAction = null;
					console.error(error);
				});
			}, function () {
				self.selectedAction = null;
			});
		}

		function confirm() {
			self.modalInstance.close();
		}

		function cancel() {
			self.modalInstance.dismiss();
		}

		// table functions
		function removeItem(row) {
			self.actions = _.filter(self.actions, function (o) {
				return o.jobactionId !== row.jobactionId;
			});
		}

	}
})();