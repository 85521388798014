(function () {
	'use strict';
	angular.module('roi.app').factory('JobActionService', JobActionService);

	JobActionService.$inject = ['$http', '$location', '$q', '$log', 'APIConfigService'];

	function JobActionService($http, $location, $q, $log, APIConfigService) {

		return {
			getActions: getActions,
			addAction: addAction,
			editAction: editAction,
			deleteAction: deleteAction
		};

		function getActions() {
			let url = APIConfigService.url('jobactions');
			return $http.get(url)
			.then(onComplete)
			.catch(onFailed);

			function onComplete(response) {
				return response.data;
			}

			function onFailed(error) {
				console.log('XHR Failed for getActions.' + error);
			}
		}

		function addAction(actionData) {
			let url = APIConfigService.url('jobactions');
			return $http.post(url, actionData)
			.then(function (response) {
				return response.data;
			})
			.catch(onFailed);

			function onFailed(error) {
				console.log('XHR Failed for addAction.' + error);
			}
		}

		function editAction(actionData) {
			let url = APIConfigService.url('jobactions', actionData.jobactionId);
			return $http.put(url, actionData)
			.then(function (response) {
				return response.data;
			})
			.catch(onFailed);

			function onFailed(error) {
				console.log('XHR Failed for editAction.' + error);
			}
		}

		function deleteAction(actionId) {
			let url = APIConfigService.url('jobactions',actionId);
			return $http.delete(url)
			.then(function (response) {
				return response.data;
			})
			.catch(onFailed);

			function onFailed(error) {
				console.log('XHR Failed for deleteAction.' + error);
			}
		}
	}
})();
