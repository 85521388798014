(function () {
	'use strict';
	angular.module('roi.users')
	.config(['$stateProvider', function ($stateProvider) {
		$stateProvider.state('applogin', {
			url: '/login',
			controller: 'LoginController',
			controllerAs: 'lc',
			templateUrl: './js/modules/session/login.html'
		})
		.state('appchangepass', {
			url: '/activate?:k',
			templateUrl: './js/modules/session/changepass.html',
			controller: 'ChangePassController',
			controllerAs: 'cpc',
		});
	}]);
})();