(function () {
	'use strict';
	angular.module('roi.transactions').controller('TransactionListController', TransactionListController);

	TransactionListController.$inject = ['$scope', '$window', '$state', '$uibModal', 'toastr', 'Utilities', 'TransactionService', 'StaticDataService'];

	function TransactionListController($scope, $window, $state, $uibModal, toastr, Utilities, TransactionService, StaticDataService) {
		let self = this;
		self.curUser = $scope.curUser;
		self.transactions = [];
		self.transactionTable = [];
		self.selectedTransaction = null;
		self.trType = '';

		//filters
		self.ui = {
			types: []
		};

		self.model = {
			transaction: null
		};

		self.formUI = {
			topup: {balance: 100}
		};
		if(self.curUser.balance < 0) {
			self.formUI.topup.balance = Math.max(100, -self.curUser.balance);
		}
		self.formUI.regex = Utilities.regex;

		self.removeItem = removeItem;
		self.addTransaction = addTransaction;
		self.topupBalance = topupBalance;
		self.viewTransactionReceipt = viewTransactionReceipt;
		self.deleteTransaction = deleteTransaction;
		self.confirm = confirm;
		self.cancel = cancel;

		let tokenId = null;
		init();

		function init() {
			getTransactions();
			StaticDataService.getStaticData().then(function (listData) {
				self.ui.types = listData.transactiontypes;
			});
		}

		function getTransactions() {
			self.transactions.length = 0;
			self.transactionTable.length = 0;
			return TransactionService.getTransactions().then(function (transactions) {
				transactions.forEach(function (transaction) {
					self.transactions.push(transaction);
					self.transactionTable.push(transaction);
				});
				return transactions;
			}).catch(function (error) {
				console.error(error);
			});
		}

		function deleteTransaction(user, transaction) {
			self.selectedTransaction = transaction;
			self.selectedUser = user;
			self.modalInstance = $uibModal.open({
				animation: true,
				scope: $scope,
				ariaLabelledBy: 'modal-title',
				ariaDescribedBy: 'modal-body',
				templateUrl: 'deletetransaction.html',
				size: 'md',
				resolve: {},
				windowClass: 'modal-danger'
			});
			self.modalInstance.result.then(function (response) {
				return TransactionService.deleteTransaction(self.selectedTransaction.transactionId).then(function (response) {
					if (response.err === false) {
						toastr.success('Transaction has been deleted', 'Success');
						getTransactions();
					} else {
						toastr.error('Transaction has not been deleted', 'Error');
					}
					self.selectedTransaction = null;
					self.selectedUser = null;
				}).catch(function (error) {
					self.selectedTransaction = null;
					self.selectedUser = null;
					console.error(error);
				});
			}, function () {
				self.selectedTransaction = null;
				self.selectedUser = null;
			});
		}

		function addTransaction(transaction, action) {
			self.selectedTransaction = transaction;

			self.modalInstance = $uibModal.open({
				animation: true,
				scope: $scope,
				ariaLabelledBy: 'modal-title',
				ariaDescribedBy: 'modal-body',
				templateUrl: 'addtransaction.html',
				size: 'lg',
				windowClass: 'modal-primary',
				resolve: {}
			});
			self.modalInstance.result.then(function (response) {
				let transactionAmount = self.model.transaction.balance;
				if (self.model.transaction.sign === '-') {
					transactionAmount = -self.model.transaction.balance;
				}
				let transaction = {
					userId: self.selectedTransaction.userId,
					typeId: 1,
					transactionAmount: transactionAmount,
					transactionDesc: self.model.transaction.reason || 'Balance Adjustment'
				};
				return TransactionService.addTransaction(transaction).then(function (response) {
					if (response.err === false) {
						toastr.success('Transaction has been added', 'Success');
						getTransactions();
					} else {
						toastr.error('Transaction has not been added', 'Error');
					}
					self.selectedTransaction = null;
				}).catch(function (error) {
					self.selectedTransaction = null;
					console.error(error);
				});
			}, function () {
				self.selectedTransaction = null;
			});

			self.modalInstance.rendered.then(function (response) {
				self.model.transaction = {
					sign: '+'
				};
			});

		}

		let handler = StripeCheckout.configure({
			key: 'pk_test_4LgQCzm5c2RThVU9jqTqCn8o',
			image: '/img/impact-etching-logo.png',
			locale: 'auto',
			token: function (token) {
				console.log(token);
				tokenId = token.id;
			},
			closed: function () {
				if (!tokenId) {
					return false;
				}
				let transaction = {
					userId: self.curUser.userId,
					typeId: 2,
					transactionAmount: self.formUI.topup.balance,
					transactionDesc: self.model.topup.topupDesc || 'Balance top-up for $' + self.formUI.topup.balance,
					email: self.curUser.email,
					tokenId: tokenId
				};
				return TransactionService.addTransaction(transaction).then(function (response) {
					if (response.err === false) {
						toastr.success('Transaction has been added', 'Success');
						getTransactions();
					} else {
						toastr.error('Transaction has not been added', 'Error');
					}
					self.selectedTransaction = null;
				}).catch(function (error) {
					self.selectedTransaction = null;
					console.error(error);
				});
			},
		});

		function viewTransactionReceipt(txn) {
			$state.go('app.transactions.invoice', { transactionId: txn.transactionId })
		}

		function topupBalance() {
			self.selectedTransaction = self.transactions[0]; // for user, it is always the first

			let payment = Math.floor(self.formUI.topup.balance * 100);
			handler.open({
				name: 'Impact Etching',
				description: 'Top up your balance',
				panelLabel: 'Top up {{amount}}',
				currency: self.curUser.currencyName,
				email: self.selectedTransaction.email,
				amount: payment
			});
		}

		function confirm() {
			self.modalInstance.close();
		}

		function cancel() {
			self.modalInstance.dismiss();
		}

		$window.addEventListener('popstate', function () {
			handler.close();
		});

		// table functions
		function removeItem(user, row) {
			let index = user.txns.indexOf(row);
			if (index !== -1) {
				user.txns.splice(index, 1);
			}
		}

	}
})();