(function () {
	'use strict';
	angular.module('roi.users').controller('UserNotificationsController', UserNotificationsController);

	UserNotificationsController.$inject = ['$scope', '$state', '$uibModal', 'NotificationService', 'StaticDataService', 'toastr'];

	function UserNotificationsController($scope, $state, $uibModal, NotificationService, StaticDataService, toastr) {
		let self = this;
		self.notifications = [];
		self.notificationTable = [];
		self.selectedNotification = null;
		let uiNotification = [{
			'nType': '',
			'nName': 'Show All'
		}];
		self.ui = {
			notificationtypes: []
		};
		let groups = undefined;

		self.editNotification = editNotification;
		self.deleteNotification = deleteNotification;
		self.confirm = confirm;
		self.cancel = cancel;

		init();

		function init() {
			StaticDataService.getStaticData().then(function(listData){
				groups = listData.groups || [];
				self.ui.notificationtypes = _.union([uiNotification], listData.notificationtypes);
				getNotifications();
			});
		}

		function getNotifications() {
			self.notifications.length = 0;
			self.notificationTable.length = 0;
			return NotificationService.getUserNotifications().then(function (notifications) {
				notifications.forEach(function (notification) {
					self.notifications.push(notification);
					self.notificationTable.push(notification);
				});
				return notifications;
			}).catch(function (error) {
				console.error(error);
			});
		}

		function editNotification(notificationId) {
			$state.go('app.notifications.notification', { notificationId: notificationId });
		}

		function deleteNotification(notification) {
			self.selectedNotification = notification;
			self.modalInstance = $uibModal.open({
				animation: true,
				scope: $scope,
				ariaLabelledBy: 'modal-title',
				ariaDescribedBy: 'modal-body',
				templateUrl: 'deletenotification.html',
				size: 'sm',
				resolve: {}
			});
			self.modalInstance.result.then(function (response) {
				return NotificationService.deleteNotification(self.selectedNotification.notificationId).then(function (response) {
					if (response.err === false) {
						toastr.success('Notification has been deleted', 'Success');
						removeItem(notification);
					} else {
						toastr.error('Notification has not been deleted, '+response.msg, 'Error');
					}
					self.selectedNotification = null;
				}).catch(function (error) {
					self.selectedNotification = null;
					console.error(error);
				});
			}, function () {
				self.selectedNotification = null;
			});
		}

		function confirm() {
			self.modalInstance.close();
		}

		function cancel() {
			self.modalInstance.dismiss();
		}

		// table functions
		function removeItem(row) {
			self.notifications = _.filter(self.notifications, function (o) {
				return o.notificationId !== row.notificationId;
			});
			self.notificationTable = _.filter(self.notificationTable, function (o) {
				return o.notificationId !== row.notificationId;
			});
		}

	}
})();