(function () {
	'use strict';
	angular.module('roi.dashboard')
	.config(['toastrConfig','$stateProvider', function (toastrConfig, $stateProvider) {
		angular.extend(toastrConfig, {
			autoDismiss: false
		});
		$stateProvider.state('app.dashboard', {
			url: '/dashboard',
			templateUrl: './js/modules/dashboard/dashboard.html',
			ncyBreadcrumb: {
				label: 'Dashboard',
			},
			controller: 'DashboardController',
			controllerAs: 'dc'
		});
	}]);
})();