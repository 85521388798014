(function () {
	'use strict';
	angular.module('roi.notifications')
	.config(['$stateProvider', function ($stateProvider) {
		$stateProvider.state('app.notifications', {
			url: '/notifications',
			abstract: true,
			ncyBreadcrumb: {
				skip: true
			},
			template: '<ui-view></ui-view>'
		});
		$stateProvider.state('app.notifications.list', {
			url: '/list',
			ncyBreadcrumb: {
				label: 'Notifications'
			},
			controller: 'NotificationListController',
			controllerAs: 'tc',
			templateUrl: './js/modules/notifications/notification-list.html'
		});
		$stateProvider.state('app.notifications.notification', {
			url: '/notification/:notificationId',
			ncyBreadcrumb: {
				parent: 'app.notifications.list',
				label: 'Notification'
			},
			controller: 'NotificationController',
			controllerAs: 'tc',
			templateUrl: './js/modules/notifications/notification.html'
		});
		$stateProvider.state('app.notifications.new', {
			url: '/notification/new',
			ncyBreadcrumb: {
				parent: 'app.notifications.list',
				label: 'Add New Notification'
			},
			controller: 'NewNotificationController',
			controllerAs: 'tc',
			templateUrl: './js/modules/notifications/notification-new.html'
		});
		$stateProvider.state('app.notifications.foruser', {
			url: '/notification/foruser',
			ncyBreadcrumb: {
				parent: 'app.notifications',
				label: 'User Notifications'
			},
			controller: 'UserNotificationsController',
			controllerAs: 'unc',
			templateUrl: './js/modules/notifications/notification-foruser.html'
		});
	}]);
})();