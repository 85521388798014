(function () {
	'use strict';
	angular.module('roi.dashboard').controller('DashboardController', DashboardController);

	DashboardController.$inject = ['$scope', '$state', '$uibModal', 'toastr', 'StatisticsService', 'StaticDataService', 'NotificationService'];

	function DashboardController($scope, $state, $uibModal, toastr, StatisticsService, StaticDataService, NotificationService) {
		let self = this,
			staticData = {},
			stat = {
				jobLabels: [],
				jobData: []
			};

		self.curUser = $scope.curUser;

		self.userNotifications = [];

		self.stat = {
			admin: {},
			assignee: {},
			customer: {}
		};

		self.activeUsers = 0;
		self.blockedUsers = 0;

		self.revenue = 0;
		self.jobcompletion = 0;
		self.jobsbytype = 0;

		self.recentusers = [];
		self.recentorders = [];

		self.markNotificationAsRead = markNotificationAsRead;
		self.stat.admin.switchChart = switchChart;
		self.stat.customer.editJob = editJob;

		let statRangeStart = moment().subtract(1, 'months').startOf('month'),
			statRangeEnd = moment().endOf('month');
		self.stat.admin.statRangeStart = statRangeStart.format('MMMM YYYY');
		self.stat.admin.statRangeEnd = statRangeEnd.format('MMMM YYYY');

		function init() {
			switch (self.curUser.ugroupId) {
				case 1: // admin
					StaticDataService.getStaticData().then(function (data) {
						staticData = data;
						getLastAddedJobsForAdmin();
					});
					getStats();
					getJobsByRangeForAdmin();
					getLastActiveUsersForAdmin();
					getUserTopStatForAdmin();
					break;
				case 2: // assignee
					StaticDataService.getStaticData().then(function (data) {
						staticData = data;
						getStats();
					});
					break;
				case 3: // customer
					StaticDataService.getStaticData().then(function (data) {
						staticData = data;
						getStats();
					});
					break;

				case 4:

					break;
			}

			NotificationService.getUserNotifications().then(function (notifications) {
				notifications.forEach(function (notification) {
					self.userNotifications.push(notification);
				});
			});
		}

		init();

		function editJob(jobId) {
			$state.go('app.jobs.job', { jobId: jobId });
		}

		function switchChart(option) {
			switch (option) {
				case 'revenue':
					getRevenueByRangeForAdmin();
					break;
				case 'job':
				default:
					getJobsByRangeForAdmin();
					break;

			}
		}

		function markNotificationAsRead(notificationId) {
			let notificationData = {
				notificationId: notificationId,
				wasRead: 1
			};
			return NotificationService.editNotification(notificationData).then(function (response) {
				if (response.err === false) {
					toastr.success('This notification has been marked as read', 'Success');
				} else {
					toastr.error('This notification has not been changed, ' + response.msg, 'Error');
				}
			}).catch(function (error) {
				console.error(error);
			});
		}

		function getStats() {
			StatisticsService.getStats().then(function (data) {
				if(data.balance) {
					self.stat.customer.balance = data.balance;
				}
				if(data.creditLimit) {
					self.stat.customer.creditLimit = data.creditLimit;
				}

				if(data.assignedusers) {
					self.assignedusers = data.assignedusers;
				}
				if(data.recentorders) {
					self.recentorders = data.recentorders;
					self.recentorders.forEach(function (val) {
						val.jobType = _.find(staticData.jobtypes, { 'jobtypeId': val.jobtypeId });
						val.jobStatus = _.find(staticData.statuses, { 'jobstatusId': val.jobstatusId });
					});
				}

				if(data.jobsbystatus) {
					self.stat.customer.jobsCompleted = data.jobsbystatus[6] || 0;

					self.stat.customer.jobsAll = 0;
					_.forEach(data.jobsbystatus, function(value, key) {
						if(key !== 6) {
							self.stat.customer.jobsAll = self.stat.customer.jobsAll + value;
						}
					});
					self.stat.customer.jobsIncompleted = self.stat.customer.jobsAll - self.stat.customer.jobsCompleted;
				}
				if (data.revenue) {
					self.revenue = data.revenue.total;
				}
				if (data.jobsbytype) {
					self.stat.admin.jobsbytype = parseInt(data.jobsbytype['1'] || 0) + parseInt(data.jobsbytype['2'] || 0);
				}
				if (data.jobcompletion) {
					self.jobcompletion = data.jobcompletion['1'];
				}
				if (data.users) {
					self.activeUsers = data.users.active;
					self.inactiveUsers = data.users.inactive;
					self.blockedUsers = data.users.blocked;
				}
			});
		}


		function getUserTopStatForAdmin() {
			self.ujLabels = [];
			self.ujData = [];
			self.ujSeries = [];
			self.urLabels = [];
			self.urSeries = [];
			self.urData = [];

			StatisticsService.getTop5ByJobs().then(function (data) {
				data.usersbyjob.forEach(function (val) {
					self.ujSeries.push(val.name);
					self.ujLabels.push(val.name);
					self.ujData.push([val.count]);
				});
			});

			StatisticsService.getTop5ByPay().then(function (data) {
				data.usersbypay.forEach(function (val) {
					self.urSeries.push(val.name);
					self.urLabels.push(val.name);
					self.urData.push([val.total]);
				});

			});

			self.urColors = [{
				backgroundColor: brandDanger,
				borderColor: brandDanger,
			}, {
				backgroundColor: brandInfo,
				borderColor: brandInfo,
			}, {
				backgroundColor: brandPrimary,
				borderColor: brandPrimary,
			}, {
				backgroundColor: brandSuccess,
				borderColor: brandSuccess,
			}, {
				backgroundColor: brandWarning,
				borderColor: brandWarning,
			}];

			self.ujColors = [{
				backgroundColor: brandPrimary,
				borderColor: brandPrimary,
			}, {
				backgroundColor: brandSuccess,
				borderColor: brandSuccess,
			}, {
				backgroundColor: brandInfo,
				borderColor: brandInfo,
			}, {
				backgroundColor: brandWarning,
				borderColor: brandWarning,
			}, {
				backgroundColor: brandDanger,
				borderColor: brandDanger,
			}];

			self.ujOptions = {
				responsive: true,
				maintainAspectRatio: false,
				scales: {
					xAxes: [{
						gridLines: {
							drawOnChartArea: true,
						},
						ticks: {
							beginAtZero: true,
						}
					}],
					yAxes: [{
						gridLines: {
							drawOnChartArea: true,
						},
						ticks: {
							beginAtZero: true,
						}
					}]
				},
			};
		}

		function getLastActiveUsersForAdmin() {
			StatisticsService.getLastActiveUsers().then(function (data) {
				self.recentusers = data.recentusers;
			});
		}

		function getLastAddedJobsForAdmin() {
			StatisticsService.getLastAddedJobs(6).then(function (data) {
				self.recentorders = data.recentorders;
				self.recentorders.forEach(function (val) {
					val.jobType = _.find(staticData.jobtypes, { 'jobtypeId': val.jobtypeId });
					val.jobStatus = _.find(staticData.statuses, { 'jobstatusId': val.jobstatusId });
				});
			});
		}

		function getJobsByRangeForAdmin() {
			// Get Jobs by Range (Admin)
			StatisticsService.getJobsByRange(statRangeStart.format('YYYY-MM-DD'), statRangeEnd.format('YYYY-MM-DD')).then(function (data) {
				self.stat.admin.chartLabel = 'Jobs';
				stat.jobLabels = data.dates;
				stat.jobData = data.quantity1;

				let elements = data.dates.length,
					target = 2,
					data0 = data.quantity,
					data1 = data.quantity1,
					data2 = data.quantity2,
					data3 = [];

				self.stat.admin.jobsAll = data.total;
				self.stat.admin.jobsIncompleted = data.total1;
				self.stat.admin.jobsCompleted = data.total2;
				self.stat.admin.jobsIncompletedP = (data.total1 / data.total) * 100;
				self.stat.admin.jobsCompletedP = (data.total2 / data.total) * 100;
				self.stat.admin.target = target;
				self.stat.admin.targetQuantity = 0;
				self.stat.admin.targetP = 0;

				for (let i = 0; i <= elements; i++) {
					data3.push(target);
					if (data.quantity[i] >= target) {
						self.stat.admin.targetQuantity++;
					}
				}

				// how many times the target is reached?
				self.stat.admin.targetP = self.stat.admin.targetQuantity / elements * 100;
				self.datasetOverrride = 'line';
				self.labels = data.dates;
				self.series = ['All Jobs', 'Incompleted Jobs', 'Completed Jobs', 'Target'];
				self.data = [data0, data1, data2, data3];
				self.colors = [{
					backgroundColor: convertHex(brandInfo, 10),
					borderColor: brandInfo,
					pointHoverBackgroundColor: '#fff',
					pointBackgroundColor: '#fff'
				}, {
					backgroundColor: convertHex(brandWarning, 30),
					borderColor: brandWarning,
					pointHoverBackgroundColor: '#fff',
					pointBackgroundColor: '#fff'
				}, {
					backgroundColor: convertHex(brandSuccess, 30),
					borderColor: brandSuccess,
					pointHoverBackgroundColor: '#fff',
					pointBackgroundColor: '#fff'
				}, {
					backgroundColor: 'transparent',
					borderColor: brandDanger,
					pointHoverBackgroundColor: '#fff',
					pointBackgroundColor: '#fff',
					borderWidth: 1,
					borderDash: [8, 5]
				}];
				self.options = {
					responsive: true,
					maintainAspectRatio: false,
					scales: {
						xAxes: [{
							gridLines: {
								drawOnChartArea: true,
							}
						}],
						yAxes: [{
							ticks: {
								beginAtZero: true,
							}
						}]
					},
					elements: {
						line: {
							tension: 0, // disables bezier curves
						},
						point: {
							backgroundColor: '#ffffff',
							radius: 5,
							hitRadius: 10,
							hoverRadius: 5,
							hoverBorderWidth: 5,
						}
					},
				};
			});
		}

		function getRevenueByRangeForAdmin() {
			// Get Revenue by Range (Admin)
			self.stat.admin.chartLabel = 'Revenue';
			StatisticsService.getRevenueByRange(statRangeStart.format('YYYY-MM-DD'), statRangeEnd.format('YYYY-MM-DD')).then(function (data) {
				let data1 = data.quantity1,
					data2 = data.quantity2,
					data3 = data.quantity3;

				self.stat.admin.revenueBalAdj = _.sum(data.quantity1);
				self.stat.admin.revenueBalTop = _.sum(data.quantity2);
				self.stat.admin.revenuePayment = _.sum(data.quantity3);

				self.datasetOverrride = 'bar';
				self.labels = data.dates;
				self.series = ['Balance Adjustment', 'Balance Top-up', 'Payment for Etching Jobs'];
				self.data = [data1, data2, data3];
				self.colors = [{
					backgroundColor: convertHex(brandSuccess, 40),
					borderColor: brandSuccess,
					pointHoverBackgroundColor: '#fff'
				}, {
					backgroundColor: convertHex(brandWarning, 40),
					borderColor: brandWarning,
					pointHoverBackgroundColor: '#fff'
				}, {
					backgroundColor: convertHex(brandInfo, 40),
					borderColor: brandInfo,
					pointHoverBackgroundColor: '#fff'
				}];
				self.options = {
					responsive: true,
					maintainAspectRatio: false,
					scales: {
						xAxes: [{
							gridLines: {
								drawOnChartArea: true,
							}
						}],
						yAxes: [{
							ticks: {
								beginAtZero: true,
							}
						}]
					},
					elements: {
						point: {
							radius: 2,
							hitRadius: 10,
							hoverRadius: 4,
							hoverBorderWidth: 3,
						}
					},
				};

			});
		}
	}

	function convertHex(hex, opacity) {
		hex = hex.replace('#', '');
		let r = parseInt(hex.substring(0, 2), 16);
		let g = parseInt(hex.substring(2, 4), 16);
		let b = parseInt(hex.substring(4, 6), 16);

		return 'rgba(' + r + ',' + g + ',' + b + ',' + opacity / 100 + ')';
	}
})();