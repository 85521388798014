(function () {
	'use strict';
	angular.module('roi.app').factory('StaticDataService', StaticDataService)
	.run(function (StaticDataService) {
		StaticDataService.init();
	});

	StaticDataService.$inject = ['$http', '$location', '$q', '$log', 'APIConfigService', 'UserGroupService', 'TaxService', 
		'TransactiontypeService', 'StatusService', 'JobTypeService', 'JobActionService', 'InstructionService', 'NotificationService'];

	function StaticDataService($http, $location, $q, $log, APIConfigService, UserGroupService, TaxService, 
	                           TransactiontypeService, StatusService, JobTypeService, JobActionService, InstructionService, NotificationService) {

		let staticData = {
			groups: undefined,
			currencies: [],
			countries: undefined,
			taxes: undefined,
			transactiontypes: undefined,
			statuses: [],
			actions: [],
			jobtypes: [],
			joboptions: undefined,
			instructions: [],
			notificationtypes: undefined
		};
		let staticDataPromise = undefined;

		return {
			init: init,
			getStaticData: getStaticData,
			getGroups: getGroups,
			getCurrencies: getCurrencies,
			getCountries: getCountries,
			getRegions: getRegions,
			getTaxes: getTaxes,
			getTransactiontypes: getTransactiontypes,
			getStatuses: getStatuses,
			getActions: getActions,
			getJobTypes: getJobTypes,
			getInstructions: getInstructions,

			refreshJobTypes: refreshJobTypes,
			updateCurrencies: updateCurrencies
		};

		function init() {
			staticData.notificationtypes = NotificationService.getNotificationtypes();
			staticData.groups = UserGroupService.getUserGroups();
			staticData.currencies = _getCurrencies();
			staticData.taxes = TaxService.getTaxes();
			staticData.transactiontypes = TransactiontypeService.getTransactiontypes();
			staticData.statuses = StatusService.getStatuses();
			staticData.jobtypes = JobTypeService.getJobTypes();
			staticData.actions = JobActionService.getActions();
			staticData.instructions = InstructionService.getInstructions();
			staticData.joboptions = _getJoboptions();

			staticData.countries = _getCountries().then(function (countries) {
				return countries;
			}).then(function (countries) {
				let geoData = {
					countries: countries,
					regions: []
				};
				return _getRegions().then(function (regions) {
					geoData.regions = regions;
					return geoData;
				});

			}).then(function (geoData) {
				geoData.countries.forEach(function (country) {
					country.regions = _.filter(geoData.regions, { countryId: country.countryId });
				});
				return geoData.countries;
			});
		}

		function getStaticData() {
			if (!staticDataPromise) {
				staticDataPromise = $q.all(staticData);
			}
			return staticDataPromise;
		}

		function getGroups() {
			return staticData.groups;
		}
		
		function getNotificationtypes() {
			return staticData.notificationtypes;
		}

		function getCurrencies() {
			return staticData.currencies;
		}

		function getCountries() {
			return staticData.countries;
		}

		function getRegions() {
			return staticData.regions;
		}

		function getTaxes() {
			return staticData.taxes;
		}

		function getTransactiontypes() {
			return staticData.transactiontypes;
		}

		function getActions() {
			return staticData.actions;
		}

		function getStatuses() {
			return staticData.statuses;
		}

		function getJobTypes() {
			return staticData.jobtypes;
		}

		function refreshJobTypes() {
			staticDataPromise = null;
			return staticData.jobtypes = JobTypeService.getJobTypes();
		}

		function getInstructions() {
			return staticData.instructions;
		}

		function updateCurrencies() {
			return _getCurrencies().then(function (response) {
				staticData.currencies = response;
				return true;
			});
		}

		function _getCurrencies() {
			let url = APIConfigService.url('currencies');
			return $http.get(url)
			.then(onComplete)
			.catch(onFailed);

			function onComplete(response) {
				return response.data;
			}

			function onFailed(error) {
				console.log('XHR Failed for getCurrencies.' + error);
			}
		}

		function _getJoboptions() {
			let url = APIConfigService.url('joboptions');
			return $http.get(url)
			.then(onComplete)
			.catch(onFailed);

			function onComplete(response) {
				return response.data;
			}

			function onFailed(error) {
				console.log('XHR Failed for getJoboptions.' + error);
			}
		}

		function _getCountries() {
			let url = APIConfigService.url('countries');
			return $http.get(url)
			.then(onComplete)
			.catch(onFailed);

			function onComplete(response) {
				return response.data;
			}

			function onFailed(error) {
				console.log('XHR Failed for getCountries.' + error);
			}
		}

		function _getRegions() {
			let url = APIConfigService.url('regions');
			return $http.get(url)
			.then(onComplete)
			.catch(onFailed);

			function onComplete(response) {
				return response.data;
			}

			function onFailed(error) {
				console.log('XHR Failed for getRegions.' + error);
			}
		}
	}
})();
