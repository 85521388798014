(function () {
	'use strict';
	angular.module('roi.notifications').controller('NotificationController', NotificationController);

	NotificationController.$inject = ['$scope', '$state', '$stateParams', 'NotificationService', 'UserService', 'StaticDataService', 'Utilities', 'toastr'];

	function NotificationController($scope, $state, $stateParams, NotificationService, UserService, StaticDataService, Utilities, toastr) {
		let self = this;
		self.notification = null;
		self.staticData = StaticDataService.getStaticData();

		self.modelform = {};
		self.model = {
			notification: null,
			recipientType: 1
		};

		let uiGroup = {
			'ugroupId': 0,
			'ugroupName': 'Show to all'
		};

		self.ui = {
			groups: [],
			types: [],
			users: []
		};

		self.discardChanges = discardChanges;
		self.submitChanges = submitChanges;

		self.endDateBeforeRender = endDateBeforeRender;
		self.endDateOnSetTime = endDateOnSetTime;
		self.startDateBeforeRender = startDateBeforeRender;
		self.startDateOnSetTime = startDateOnSetTime;

		init();

		function init() {
			StaticDataService.getStaticData().then(function (listData) {
				self.ui.groups = _.union([uiGroup], listData.groups);
				self.ui.types = listData.notificationtypes;
				return getNotification($stateParams.notificationId);
			}).then(function () {
				return getUsers();
			}).then(function (users) {
				if (self.model.notification.userId !== null) {
					self.model.recipientType = 1;
					users.forEach(function (user) {
						if (user.userId === self.model.notification.userId) {
							self.model.notification.user = user;
						}
					});
				} else {
					self.model.recipientType = 2;
					self.ui.groups.forEach(function (group) {
						if (group.ugroupId === self.model.notification.ugroupId) {
							self.model.notification.group = group;
						}
					});
				}
			});
		}

		function getUsers() {
			self.ui.users.length = 0;
			return UserService.getUsers().then(function (users) {
				users.forEach(function (user) {
					self.ui.users.push(user);
				});
				return users;
			}).catch(function (error) {
				console.error(error);
			});
		}

		function getNotification(notificationId) {
			return NotificationService.getNotification(notificationId).then(function (notification) {
				self.model.notification = angular.copy(notification);
				self.notification = angular.copy(notification);
				return notification;
			}).catch(function (error) {
				console.error(error);
			});
		}

		function submitChanges() {
			let submittedData = angular.copy(self.model.notification);
			if (self.model.recipientType === 1) {
				submittedData.userId = parseInt(self.model.notification.user.userId);
				delete submittedData.user;
				delete submittedData.group;
				delete submittedData.groupId;
			}
			if (self.model.recipientType === 2) {
				submittedData.groupId = (submittedData.groupId) ? parseInt(submittedData.groupId) : 0;
				delete submittedData.user;
				delete submittedData.userId;
				delete submittedData.group;
			}

			let notificationData = Utilities.filterChanges(self.notification, submittedData);
			if (_.isEmpty(notificationData)) {
				toastr.warning('No notification data have been changed', 'Warning');
				return false;
			}
			notificationData.notificationId = self.model.notification.notificationId;
			editNotification(notificationData);
		}

		function discardChanges() {
			self.model.notification = angular.copy(self.notification);
			self.model.notification.user = _.find(self.ui.users, { userId: self.model.notification.userId });
			self.model.recipientType = (!!self.model.notification.user) ? 1 : 2;
		}

		function startDateOnSetTime() {
			$scope.$broadcast('start-date-changed');
		}

		function endDateOnSetTime() {
			$scope.$broadcast('end-date-changed');
		}

		function startDateBeforeRender($dates) {
			if (self.model.notification && self.model.notification.endDate) {
				let activeDate = moment(self.model.notification.endDate);

				$dates.filter(function (date) {
					return date.localDateValue() >= activeDate.valueOf();
				}).forEach(function (date) {
					date.selectable = false;
				});
			}
		}

		function endDateBeforeRender($view, $dates) {
			if (self.model.notification && self.model.notification.startDate) {
				let activeDate = moment(self.model.notification.startDate).subtract(1, $view).add(1, 'minute');

				$dates.filter(function (date) {
					return date.localDateValue() <= activeDate.valueOf();
				}).forEach(function (date) {
					date.selectable = false;
				});
			}
		}

		function editNotification(notificationData) {
			return NotificationService.editNotification(notificationData).then(function (response) {
				console.log('Notification has been changed to ', notificationData);
				if (response.err === false) {
					toastr.success('Notification data have been changed', 'Success');
					$state.go('app.notifications.list');
				} else {
					toastr.error('Notification data have not been changed, '+response.msg, 'Error');
				}
			}).catch(function (error) {
				console.error(error);
			});
		}
	}
})();
