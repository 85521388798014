(function () {
	'use strict';
	angular.module('roi.app').factory('NotificationService', NotificationService);

	NotificationService.$inject = ['$http', '$location', '$q', '$log', 'APIConfigService'];

	function NotificationService($http, $location, $q, $log, APIConfigService) {
		let listPromise = undefined;
		return {
			getNotificationtypes: getNotificationtypes,
			getNotifications: getNotifications,
			getUserNotifications: getUserNotifications,
			getNotification: getNotification,
			addNotification: addNotification,
			editNotification: editNotification,
			deleteNotification: deleteNotification
		};

		function getNotificationtypes() {
			if(listPromise)
				return listPromise;
			let url = APIConfigService.url('notificationtypes');
			listPromise = $http.get(url)
			.then(onComplete)
			.catch(onFailed);

			return listPromise;
			function onComplete(response) {
				return response.data;
			}

			function onFailed(error) {
				console.log('XHR Failed for getNotificationtypes.' + error);
			}
		}

		function getNotifications() {
			let notifications = [];
			let url = APIConfigService.url('alerts');
			return $http.get(url)
			.then(function (response) {
				notifications = response.data;
				return notifications;
			})
			.catch(onFailed);

			function onFailed(error) {
				console.error('XHR Failed for getNotifications.' + error);
			}
		}

		function getUserNotifications() {
			let notifications = [];
			let url = APIConfigService.url('useralerts');
			return $http.get(url)
			.then(function (response) {
				notifications = response.data;
				return notifications;
			})
			.catch(onFailed);

			function onFailed(error) {
				console.error('XHR Failed for getNotifications.' + error);
			}
		}

		function getNotification(notificationId) {
			let notification = {};
			let url = APIConfigService.url('alerts', notificationId);
			return $http.get(url)
			.then(function (response) {
				return response.data;
			})
			.catch(onFailed);

			function onFailed(error) {
				console.error('XHR Failed for getNotification.' + error);
			}
		}

		function addNotification(notificationData) {
			let url = APIConfigService.url('alerts');
			return $http.post(url, notificationData).then(function (response) {
				return response.data;
			})
			.catch(onFailed);

			function onFailed(error) {
				console.log('XHR Failed for addNotification.' + error.data);
			}
		}

		function editNotification(notificationData) {
			let url = APIConfigService.url('alerts',notificationData.notificationId);
			return $http.put(url, notificationData).then(function (response) {
				return response.data;
			})
			.catch(onFailed);

			function onFailed(error) {
				console.log('XHR Failed for editNotification.' + error.data);
			}
		}

		function deleteNotification(notificationId) {
			let url = APIConfigService.url('alerts', notificationId);
			return $http.delete(url).then(function (response) {
				return response.data;
			})
			.catch(onFailed);

			function onFailed(error) {
				console.log('XHR Failed for deleteNotification.' + error.data);
			}
		}
	}
})();
