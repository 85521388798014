(function () {
	'use strict';
	angular.module('roi.taxes').controller('TaxListController', TaxListController);

	TaxListController.$inject = ['$scope', '$state', '$uibModal', 'TaxService', 'StaticDataService', 'toastr'];

	function TaxListController($scope, $state, $uibModal, TaxService, StaticDataService, toastr) {
		let self = this;

		self.taxes = [];
		self.taxTable = [];
		self.selectedTax = null;

		self.editTax = editTax;
		self.deleteTax = deleteTax;
		self.confirm = confirm;
		self.cancel = cancel;

		init();

		function init() {
			TaxService.getTaxes().then(function(taxes){
				self.taxes = taxes;
				self.taxTable = angular.copy(taxes);
			})
		}

		function editTax(taxId) {
			$state.go('app.taxes.tax', { taxId: taxId });
		}

		function deleteTax(tax) {
			self.selectedTax = tax;
			self.modalInstance = $uibModal.open({
				animation: true,
				scope: $scope,
				ariaLabelledBy: 'modal-title',
				ariaDescribedBy: 'modal-body',
				templateUrl: 'deletetax.html',
				size: 'sm',
				resolve: {}
			});
			self.modalInstance.result.then(function (response) {
				return TaxService.deleteTax(self.selectedTax.taxId).then(function (response) {
					if (response.err === false) {
						toastr.success('Tax has been deleted', 'Success');
						removeItem(tax);
					} else {
						toastr.error('Tax has not been deleted', 'Error');
					}
					self.selectedTax = null;
				}).catch(function (error) {
					self.selectedTax = null;
					console.error(error);
				});
			}, function () {
				self.selectedTax = null;
			});
		}

		function confirm() {
			self.modalInstance.close();
		}

		function cancel() {
			self.modalInstance.dismiss();
		}

		// table functions
		function removeItem(row) {
			self.taxes = _.filter(self.taxes, function (o) {
				return o.taxId !== row.taxId;
			});
		}

	}
})();