(function () {
	'use strict';
	angular.module('roi.instructions').controller('InstructionListController', InstructionListController);

	InstructionListController.$inject = ['$scope', '$state', '$uibModal', 'InstructionService', 'StaticDataService', 'toastr'];

	function InstructionListController($scope, $state, $uibModal, InstructionService, StaticDataService, toastr) {
		let self = this;
		self.selectedInstruction = null;

		self.editInstruction = editInstruction;
		self.deleteInstruction = deleteInstruction;
		self.confirm = confirm;
		self.cancel = cancel;

		init();

		function init() {
			InstructionService.getInstructions().then(function(instructions){
				self.instructions = instructions;
				self.instructionTable = angular.copy(instructions);
			})
		}

		function editInstruction(instructionId) {
			$state.go('app.instructions.instruction', { id: instructionId });
		}

		function deleteInstruction(instruction) {
			self.selectedInstruction = instruction;
			self.modalInstance = $uibModal.open({
				animation: true,
				scope: $scope,
				ariaLabelledBy: 'modal-title',
				ariaDescribedBy: 'modal-body',
				templateUrl: 'deleteinstruction.html',
				size: 'sm',
				resolve: {}
			});
			self.modalInstance.result.then(function (response) {
				return InstructionService.deleteInstruction(self.selectedInstruction.instructionId).then(function (response) {
					if (response.err === false) {
						toastr.success('Instruction has been deleted', 'Success');
						removeItem(instruction);
					} else {
						toastr.error('Instruction has not been deleted', 'Error');
					}
					self.selectedInstruction = null;
				}).catch(function (error) {
					self.selectedInstruction = null;
					console.error(error);
				});
			}, function () {
				self.selectedInstruction = null;
			});
		}

		function confirm() {
			self.modalInstance.close();
		}

		function cancel() {
			self.modalInstance.dismiss();
		}

		// table functions
		function removeItem(row) {
			self.instructions = _.filter(self.instructions, function (o) {
				return o.instructionId !== row.instructionId;
			});
		}
	}
})();
