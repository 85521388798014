(function () {
	'use strict';
	angular.module('roi.actions').controller('NewActionController', NewActionController);

	NewActionController.$inject = ['$scope', '$state', 'JobActionService', 'StaticDataService', 'Utilities', 'toastr'];

	function NewActionController($scope, $state, JobActionService, StaticDataService, Utilities, toastr) {
		let self = this;
		self.action = null;

		self.modelform = {};
		self.model = {
			action: null
		};
		self.ui={};

		self.submitChanges = submitChanges;
		self.someSelected = Utilities.someSelected;
		self.modelFilter = Utilities.modelFilter;

		init();

		function init() {
			StaticDataService.getStaticData().then( function(listData) {
				self.ui.jobtypes = listData.jobtypes;
				self.ui.statuses = listData.statuses;
			} )
		}

		function submitChanges() {
			let submittedData = angular.copy(self.model.action);
			submittedData.jobtypeIds = _.map(_.keys(self.model.action.jobtype), _.parseInt);
			delete submittedData.jobtype;
			addAction(submittedData);
		}

		function addAction(actionData) {
			return JobActionService.addAction(actionData).then(function (response) {
				console.log('Action has been added ', actionData);
				if (response.err === false) {
					toastr.success('Action data have been added', 'Success');
					$state.go('app.actions.list');
				} else {
					toastr.error('Action data have not been added', 'Error');
				}
			}).catch(function (error) {
				console.error(error);
			});
		}
	}
})();
