(function () {
	'use strict';
	angular.module('roi.actions').controller('ActionController', ActionController);

	ActionController.$inject = ['$scope', '$state', '$stateParams', 'JobActionService', 'StaticDataService', 'Utilities', 'toastr'];

	function ActionController($scope, $state, $stateParams, JobActionService, StaticDataService, Utilities, toastr) {
		let self = this;
		self.action = null;
		let actions = [];

		self.modelform = {};
		self.model = {
			action: null
		};
		self.ui={};

		self.discardChanges = discardChanges;
		self.submitChanges = submitChanges;
		self.someSelected = Utilities.someSelected;
		self.isCheckboxSelected = Utilities.isCheckboxSelected;
		self.modelFilter = Utilities.modelFilter;

		init();

		function init() {
			StaticDataService.getStaticData().then( function(listData) {
				self.ui.jobtypes = listData.jobtypes;
				JobActionService.getActions().then( function(list) {
					actions = list;
					getAction( $stateParams.actionId );
				} )
			} )
		}

		function getAction(jobactionId) {
			let action = _.find(actions, { jobactionId: parseInt(jobactionId) });
			action.jobtype = Utilities.arrayToObjectModel(action.jobtypeIds);
			self.action = action;
			self.model.action = angular.copy(action);
		}

		function submitChanges() {
			let submittedData = angular.copy(self.model.action);
			submittedData.jobtypeIds = _.map(_.keys(self.model.action.jobtype), _.parseInt);
			delete submittedData.jobtype;
			let actionData = Utilities.filterChanges(self.action, submittedData);
			if (_.isEmpty(actionData)) {
				toastr.warning('No action data have been changed', 'Warning');
				return false;
			}
			actionData.jobactionId = self.model.action.jobactionId;
			editAction(actionData);
		}

		function discardChanges() {
			self.model.action = angular.copy(self.action);
		}

		function editAction(actionData) {
			return JobActionService.editAction(actionData).then(function (response) {
				if (response.err === false) {
					toastr.success('Action data have been changed', 'Success');
					$state.go('app.actions.list');
				} else {
					toastr.error('Action data have not been changed', 'Error');
				}
			}).catch(function (error) {
				console.error(error);
			});
		}
	}
})();
