(function () {
	'use strict';
	angular.module('roi.jobtypes').controller('WorkFlowController', WorkFlowController);

	WorkFlowController.$inject = ['$scope', '$state', '$stateParams', 'JobTypeService', 'StaticDataService', 'Utilities', 'toastr'];

	function WorkFlowController($scope, $state, $stateParams, JobTypeService, StaticDataService, Utilities, toastr) {
		let self = this;
		self.workflow = null;

		self.jobtype = {};
		self.workflow = {};
		self.modelform = {};
		self.groups = [];
		self.statuses = [];
		self.model = {
			workflow: null
		};

		self.discardChanges = discardChanges;
		self.submitChanges = submitChanges;
		self.modelFilter = Utilities.modelFilter;
		self.isCheckboxSelected = Utilities.isCheckboxSelected;

		init();

		function init() {
			StaticDataService.getStaticData().then( function(listData) {
				self.jobtype = _.find(listData.jobtypes, { jobtypeId: parseInt($stateParams.jobtypeId) });
				let workflow = angular.copy(self.jobtype);
				workflow.actions = _.filter(listData.actions, function (action) {
					return (_.indexOf(workflow.jobactionIds, action.jobactionId) > -1);
				});
				workflow.actions = _.sortBy(workflow.actions,'jobactionId');
				
				self.groups = _.filter(listData.groups,(group)=>_.map(_.keys(workflow.groups),_.ary(parseInt,1)));
				self.statuses = _.filter(listData.statuses, (status)=>!!~status.jobtypeIds.indexOf(self.jobtype.jobtypeId));
				for(let ugroupId in workflow.groups) {
					for(let jobstatusId in workflow.groups[ugroupId]){
						workflow.groups[ugroupId][jobstatusId] = Utilities.arrayToObjectModel(workflow.groups[ugroupId][jobstatusId]);
					}
				}
				self.workflow = workflow;
				self.model.workflow = angular.copy(workflow);
			} );
		}

		function submitChanges() {
			let submittedData = {
				jobtypeId: self.jobtype.jobtypeId,
				groups: angular.copy(self.model.workflow.groups)
			};

			for(let ugroupId in submittedData.groups) {
				for(let jobstatusId in submittedData.groups[ugroupId]){
					submittedData.groups[ugroupId][jobstatusId] = 
						_.map(_.keys(_.pickBy(submittedData.groups[ugroupId][jobstatusId],(o)=>o)), _.ary(parseInt,1));
				}
			}
			editWorkFlow(submittedData);
		}

		function discardChanges() {
			self.model.workflow = angular.copy(self.workflow);
		}

		function editWorkFlow(workflowData) {
			return JobTypeService.editJobType(workflowData).then(function (response) {
				console.log('WorkFlow has been changed to ', workflowData);
				if (response.err === false) {
					toastr.success('WorkFlow data have been changed', 'Success');
					StaticDataService.refreshJobTypes();
					$state.go('app.jobtypes.list');
				} else {
					toastr.error('WorkFlow data have not been changed', 'Error');
				}
			}).catch(function (error) {
				console.error(error);
			});
		}
	}
})();
