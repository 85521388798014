(function() {
	'use strict';
	angular.module( 'roi.users' ).controller( 'ChangePassController', ChangePassController );

	ChangePassController.$inject = ['$scope', '$state', '$stateParams', '$q', 'Upload', 'toastr',
		'JobService', 'UserService', 'StaticDataService', 'APIConfigService', 'Utilities', 'SessionService'];

	function ChangePassController($scope, $state, $stateParams, $q, Upload, toastr,
	                         JobService, UserService, StaticDataService, APIConfigService, Utilities, SessionService) {
		let self = this;
		self.modelform = {};
		self.formdata = {
			password: '',
			newpassword: '',
			newpassword2: ''
		};
		self.passwordmatch = true;

		self.formUI = {};

		self.submit = submit;

		init();

		function init() {
		}

		function submit() {
			SessionService.changepass( $stateParams.k, self.formdata.password, self.formdata.newpassword, self.formdata.newpassword2 )
			.then( function(response) {
				if(response.err === true) {
					toastr.error( response.msg ? response.msg : 'Unknown error' );
					return;
				}
				$state.go( 'applogin' );
			} ).catch( function(error) {
				toastr.error( error.msg ? error.msg : 'Unknown error' );
			} )
		}
	}
})();
