(function () {
	'use strict';
	angular.module('roi.usergroups').controller('UserGroupController', UserGroupController);

	UserGroupController.$inject = ['$scope', '$state', '$stateParams', 'UserGroupService', 'StaticDataService', 'Utilities', 'toastr'];

	function UserGroupController($scope, $state, $stateParams, UserGroupService, StaticDataService, Utilities, toastr) {
		let self = this;
		self.usergroup = null;
		self.staticData = StaticDataService.getStaticData();

		self.modelform = {};
		self.model = {
			usergroup: null
		};

		self.discardChanges = discardChanges;
		self.submitChanges = submitChanges;

		init();

		function init() {
			getUserGroup(parseInt($stateParams.ugroupId));
		}

		function getUserGroup(ugroupId) {
			UserGroupService.getUserGroup(ugroupId).then(function(usergroup){
				self.usergroup = angular.copy(usergroup);
				self.model.usergroup = angular.copy(usergroup);
			});
		}

		function submitChanges() {
			let submittedData = angular.copy(self.model.usergroup),
				userGroupData = Utilities.filterChanges(self.usergroup, submittedData);
			if (_.isEmpty(userGroupData)) {
				toastr.warning('No user group data have been changed', 'Warning');
				return false;
			}
			userGroupData.ugroupId = self.model.usergroup.ugroupId;
			editUserGroup(userGroupData);
		}

		function discardChanges() {
			self.model.usergroup = angular.copy(self.usergroup);
		}

		function editUserGroup(userGroupData) {
			return UserGroupService.editUserGroup(userGroupData).then(function (response) {
				console.log('User Group has been changed to ', userGroupData);
				if (response.err === false) {
					toastr.success('User Group data have been changed', 'Success');
					$state.go('app.usergroups.list');
				} else {
					toastr.error('User Group data have not been changed', 'Error');
				}
			}).catch(function (error) {
				console.error(error);
			});
		}
	}
})();
