(function () {
	'use strict';
	angular.module('roi.app').factory('TaxService', TaxService);

	TaxService.$inject = ['$http', '$location', '$q', '$log', 'APIConfigService'];

	function TaxService($http, $location, $q, $log, APIConfigService) {

		let listPromise = undefined;
		return {
			getTaxes: getTaxes,
			addTax: addTax,
			editTax: editTax,
			deleteTax: deleteTax
		};

		function getTaxes() {
			if(listPromise)
				return listPromise;
			let url = APIConfigService.url('taxes');
			listPromise = $http.get(url)
			.then(onComplete)
			.catch(onFailed);

			return listPromise;
			function onComplete(response) {
				return response.data;
			}

			function onFailed(error) {
				console.log('XHR Failed for getTaxes.' + error);
			}
		}

		function addTax(taxData) {
			let url = APIConfigService.url('taxes');
			return $http.post(url, taxData)
			.then(function (response) {
				listPromise = undefined;
				return response.data;
			})
			.catch(onFailed);

			function onFailed(error) {
				console.log('XHR Failed for addTax.' + error);
			}
		}
		
		function editTax(taxData) {
			let url = APIConfigService.url('taxes', taxData.taxId);
			return $http.put(url, taxData)
			.then(function (response) {
				listPromise = undefined;
				return response.data;
			})
			.catch(onFailed);

			function onFailed(error) {
				console.log('XHR Failed for editTax.' + error);
			}
		}

		function deleteTax(taxId) {
			let url = APIConfigService.url('taxes', taxId);
			return $http.delete(url)
			.then(function (response) {
				listPromise = undefined;
				return response.data;
			})
			.catch(onFailed);

			function onFailed(error) {
				console.log('XHR Failed for deleteTax.' + error);
			}
		}
	}
})();
