(function () {
	'use strict';
	angular.module('roi.taxes').controller('TaxController', TaxController);

	TaxController.$inject = ['$scope', '$state', '$stateParams', 'TaxService', 'StaticDataService', 'Utilities', 'toastr'];

	function TaxController($scope, $state, $stateParams, TaxService, StaticDataService, Utilities, toastr) {
		let self = this;
		self.tax = null;
		self.staticData = StaticDataService.getStaticData();

		self.modelform = {};
		self.model = {
			tax: null
		};

		self.formUI = {
			regex: Utilities.regex
		};

		self.discardChanges = discardChanges;
		self.submitChanges = submitChanges;

		init();

		function init() {
			TaxService.getTaxes().then(function(taxes){
				let tax = _.find(taxes, { taxId: parseInt($stateParams.taxId) });
				self.tax = angular.copy(tax);
				self.model.tax = angular.copy(tax);
			});
		}

		function submitChanges() {
			let submittedData = angular.copy(self.model.tax);
				submittedData.rate = parseFloat(submittedData.rate);
			let taxData = Utilities.filterChanges(self.tax, submittedData);
			if (_.isEmpty(taxData)) {
				toastr.warning('No tax data have been changed', 'Warning');
				return false;
			}
			taxData.taxId = self.model.tax.taxId;
			editTax(taxData);
		}

		function discardChanges() {
			self.model.tax = angular.copy(self.tax);
		}

		function editTax(taxData) {
			return TaxService.editTax(taxData).then(function (response) {
				console.log('Tax has been changed to ', taxData);
				if (response.err === false) {
					toastr.success('Tax data have been changed', 'Success');
					$state.go('app.taxes.list');
				} else {
					toastr.error('Tax data have not been changed: '+response.msg, 'Error');
				}
			}).catch(function (error) {
				console.error(error);
			});
		}
	}
})();
