(function () {
	'use strict';
	angular.module('roi.statuses').controller('NewStatusController', NewStatusController);

	NewStatusController.$inject = ['$scope', '$state', 'StatusService', 'StaticDataService', 'Utilities', 'toastr'];

	function NewStatusController($scope, $state, StatusService, StaticDataService, Utilities, toastr) {
		let self = this;
		self.status = null;
		self.jobtypes = [];
		self.modelform = {};
		self.model = {
			status: null
		};

		self.submitChanges = submitChanges;
		self.someSelected = Utilities.someSelected;
		self.modelFilter = Utilities.modelFilter;

		StaticDataService.getStaticData().then(function(listData){
			self.jobtypes = listData.jobtypes;
		});
		function submitChanges() {
			let submittedData = angular.copy(self.model.status);
			submittedData.jobtype_ids = _.map(_.keys(self.model.status.jobtype), _.parseInt);
			delete submittedData.jobtype;
			addStatus(submittedData);
		}

		function addStatus(statusData) {
			return StatusService.addStatus(statusData).then(function (response) {
				console.log('Status has been added ', statusData);
				if (response.err === false) {
					toastr.success('Status data have been added', 'Success');
					$state.go('app.statuses.list');
				} else {
					toastr.error('Status data have not been added', 'Error');
				}
			}).catch(function (error) {
				console.error(error);
			});
		}
	}
})();
