(function () {
	'use strict';
	angular.module('roi.jobs').controller('NewJobCatalogController', NewJobCatalogController);

	NewJobCatalogController.$inject = ['$scope', '$state', '$location', '$stateParams', '$q', 'Upload', 'toastr',
		'JobService', 'UserService', 'StaticDataService', 'APIConfigService', 'Utilities', 'CatalogsService'];

	function NewJobCatalogController($scope, $state, $location, $stateParams, $q, Upload, toastr,
	                          JobService, UserService, StaticDataService, APIConfigService, Utilities, CatalogsService) {
		let self = this;
		self.jobtypeId = 3;
		self.job = null;
		self.act = null;
		self.modelform = {
			jobActionId: null
		};
		self.model = {
			job: null
		};
		self.catalogs = [];
		self.actions = [];
		self.formUI = {
			assignees: []
		};

		self.awsStorage = 'storage';
		if($location.host().indexOf( '.local' ) > 0 || $location.host().indexOf( 'localhost' ) >= 0) {
			self.awsStorage = 'storage-local';
		}
		if($location.host().indexOf( 'demo' ) >= 0) {
			self.awsStorage = 'storage-stage';
		}

		self.formUI.regex = Utilities.regex;
		self.formUI.image = [];
		self.addFiles = addFiles;
		self.uploadFiles = uploadFiles;
		self.removeImage = removeImage;
		self.discardChanges = discardChanges;
		self.submitChanges = submitChanges;

		self.title = 'Add New Catalog Request';

		switch (self.jobtypeId) {
			case 3:
				self.title = 'Catalog Request';
				break;
			case 2:
				self.title = 'Custom Artwork Request';
				break;
			default:
				self.title = 'Add New Job';
				break;
		}
		init();

		function init() {
			$q.all({
				assignees: UserService.getUsers({ ugroupId: 4 }),
				listData: StaticDataService.getStaticData(),
				catalogs: CatalogsService.getCatalogs()
			}).then(function (data) {
				self.formUI.assignees = angular.copy(data.assignees);
				let job = {
					assignee: 0,
					jobtypeId: self.jobtypeId,
					userId: self.$resolve.curUser.userId,
					user: self.$resolve.curUser,
					width: 0,
					height: 0,
					options: '',
				};
				return $q.all({
					user: self.$resolve.curUser,
					job: job,
					listData: data.listData,
					catalogs: data.catalogs
				});
			}).then(function (data) {
				let job = data.job;
				self.model.job = job;
				self.job = angular.copy(job);
				data.catalogs.forEach( function(catalog) {
					self.catalogs.push( catalog );
				} );
			});
		}

		function removeImage(imageIndex) {
			if (imageIndex !== -1) {
				self.model.job.files.splice(imageIndex, 1);
			}
		}

		function addFiles(files, errFiles) {
			if (errFiles && errFiles.length > 0) {
				errFiles.forEach(function (errfile) {
					let errReason = 'Wrong file format or file size.';
					switch (errfile.$error) {
						case 'maxFiles':
							errReason = 'Too many files, only ' + errfile.$errorParam + ' are allowed.';
							break;
						case 'maxSize':
							errReason = 'Your file size is too large, only ' + errfile.$errorParam + ' is allowed.';
							break;
					}
					toastr.error(errfile.name + ' has not been uploaded. ' + errReason, 'Error');
				});
			}
			if (files && files.length > 0) {
				self.model.job.files = files;
				files.forEach(function (file, i) {
					file.icon = Utilities.assignFileIcon(file.name);
					self.formUI.image[i] = {
						progress: 0,
						style: 'width: 0%',
						cache: +new Date()
					};
				});

			}
		}

		function uploadFiles() {
			self.model.job.images = [];
			let promisesArr = [],
				deferred = $q.defer();
			self.model.job.files.forEach(function (file, i) {
				promisesArr.push(Upload.upload({
					url: APIConfigService.url('files'), //'https://impactetching.s3.amazonaws.com/',
					/*url: 'https://impactetching.s3.amazonaws.com/',
					method: 'POST',
					data: {
						key: 'user/3/'+file.name, // the key to store the file on S3, could be file name or customized
						AWSAccessKeyId: 'AKIAJEICFC6X7RM6HUXA',
						acl: 'public-read',
						policy: 'ewogICJleHBpcmF0aW9uIjogIjIwMjAtMDEtMDFUMDA6MDA6MDBaIiwKICAiY29uZGl0aW9ucyI6IFsKICAgIHsiYnVja2V0IjogImltcGFjdGV0Y2hpbmcifSwKICAgIFsic3RhcnRzLXdpdGgiLCAiJGtleSIsICIiXSwKICAgIHsiYWNsIjogInB1YmxpYy1yZWFkIn0sCiAgICBbInN0YXJ0cy13aXRoIiwgIiRDb250ZW50LVR5cGUiLCAiIl0sCiAgICBbInN0YXJ0cy13aXRoIiwgIiRmaWxlbmFtZSIsICIiXSwKICAgIFsiY29udGVudC1sZW5ndGgtcmFuZ2UiLCAwLCA1MjQyODgwMDBdCiAgXQp9',
						signature: 'K2NenNrEEbq6Qr5TqT7lkay+8gc=',
						'Content-Type': file.type != '' ? file.type : 'application/octet-stream',
						filename: file.name,
						file: file
					}*/
					data: {
						image: file,
						userId: self.model.job.userId,
					}
				}).then(function (resp) {
					console.log(resp);
					self.model.job.images[i] = resp.data;
					toastr.success(resp.config.data.image.name + ' uploaded', 'Success');
					console.log(resp.data.filenames);
					return resp.data.filenames[0];
				}, function (resp) {
					toastr.error(resp.config.data.image.name + ' has not been uploaded', 'Error');
					return resp;
				}, function (evt) {
					self.formUI.image[i] = {
						progress: parseInt(100.0 * evt.loaded / evt.total),
						style: 'width: ' + parseInt(100.0 * evt.loaded / evt.total) + '%',
						cache: +new Date()
					};
					console.log('progress: ' + self.formUI.image[i].progress + '% ' + evt.config.data.image.name);
				}));
			});

			return $q.all(promisesArr);
		}

		function submitChanges() {
			// submit only allowed values
			let newJob = {
				//fee: parseFloat(self.model.job.fee),
				userId: parseInt(self.model.job.userId),
				jobtypeId: parseInt(self.model.job.jobtypeId),
				options: angular.toJson(self.model.job.jsonoptions),
				width: parseInt(self.model.job.width),
				height: parseInt(self.model.job.height),
				instructions: self.model.job.instructions,
				assigneeId: parseInt(self.model.job.assignee.userId),
				filenamesUpload: self.model.job.filenamesUpload
			};
			if (self.model.job.files && self.model.job.files.length > 0) {
				uploadFiles().then(function (filenames) {
						newJob.filenamesUpload = filenames;
						addJob(newJob);
						return true;
					},
					function (err) {
						console.log('error', err);
						return false;
					});
			} else {
				delete newJob.filenamesUpload;
				delete newJob.options;
				delete newJob.width;
				delete newJob.height;
				addJob(newJob);
			}

		}

		function addJob(jobData) {
			return JobService.addJob(jobData).then(function (response) {
				console.log('Job has been added. ', jobData);
				if (undefined !== response && false === response.err) {
					toastr.success('Job data #' + response.id + ' have been created', 'Success');
					self.modelform.$pristine = true;
					$state.go('app.jobs.list');
				} else {
					toastr.error('Job data have not been created. Server says: ' + response.msg, 'Error');
					console.error(response.msg);
				}
			}).catch(function (error) {
				console.error(error);
			});
		}

		function discardChanges() {
			self.act = null;
			self.formUI.image = {
				progress: 0,
				style: 'width: 0%',
				cache: +new Date()
			};
			self.model.job = angular.copy(self.job);
			self.modelform.$pristine = true;
		}
	}
})();