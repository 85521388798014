(function () {
	'use strict';
	angular.module('roi.app').factory('TransactionService', TransactionService);

	TransactionService.$inject = ['$http', '$location', '$q', '$log', 'APIConfigService', 'UserService', 'StaticDataService'];

	function TransactionService($http, $location, $q, $log, APIConfigService, UserService, StaticDataService) {

		return {
			getTransactions: getTransactions,
			getTransaction: getTransaction,
			addTransaction: addTransaction,
			deleteTransaction: deleteTransaction
		};

		function getTransactions(params) {
			let url = APIConfigService.url('txns', null, null, params);
			return $http.get(url)
			.then(function (response) {
				return response.data;
			})
			.then(function (userTransactions) {
				return applyStaticData(userTransactions);
			})
			.catch(onFailed);

			function onFailed(error) {
				console.error('XHR Failed for getTransactions.', error);
			}
		}

		function getTransaction(transactionId) {
			let url = APIConfigService.url('txns', transactionId, null, null);
			return $http.get(url)
			.then(function (response) {
				return applyStaticData([response.data]);
			}).then(function (transactions) {
				return transactions[0];
			})
			.catch(onFailed);

			function onFailed(error) {
				console.log('XHR Failed for getTransaction.', error);
			}
		}

		function addTransaction(transactionData) {
			let url = APIConfigService.url('txns', null, null, null);
			return $http.post(url, transactionData)
			.then(function (response) {
				return response.data;
			})
			.catch(onFailed);

			function onFailed(error) {
				console.log('XHR Failed for addTransaction.', error);
			}
		}

		function deleteTransaction(transactionId) {
			let url = APIConfigService.url('txns', transactionId, null, null);
			return $http.delete(url)
			.then(function (response) {
				return response.data;
			})
			.catch(onFailed);

			function onFailed(error) {
				console.log('XHR Failed for deleteTransaction.', error);
			}
		}

		function applyStaticData(userTransactions) {
			return StaticDataService.getTransactiontypes().then(function (transactiontypes) {
				userTransactions = transactiontypesToTransactions(userTransactions, transactiontypes);
				return userTransactions;
			});
		}

		function transactiontypesToTransactions(userTransactions, transactiontypes) {
			return _.each(userTransactions, function (userTransaction) {
				let txns = userTransaction;
				if (userTransaction.hasOwnProperty('txns')) {
					txns = userTransaction.txns;
				}
				txns.forEach(function (transaction) {
					transaction.transactiontype = _.find(transactiontypes, { trId: transaction.typeId });
				});

			});
		}
	}
})();
