(function () {
	'use strict';
	angular.module('roi.app').factory('JobTypeService', JobTypeService);

	JobTypeService.$inject = ['$http', '$location', '$q', '$log', 'APIConfigService'];

	function JobTypeService($http, $location, $q, $log, APIConfigService) {

		return {
			getJobTypes: getJobTypes,
			addJobType: addJobType,
			editJobType: editJobType,
			deleteJobType: deleteJobType
		};

		function getJobTypes() {
			let url = APIConfigService.url('jobtypes');
			return $http.get(url)
			.then(onComplete)
			.catch(onFailed);

			function onComplete(response) {
				return response.data;
			}

			function onFailed(error) {
				console.log('XHR Failed for getJobTypes.' + error);
			}
		}

		function addJobType(jobTypeData) {
			let url = APIConfigService.url('jobtypes');
			return $http.post(url, jobTypeData)
			.then(function (response) {
				return response.data;
			})
			.catch(onFailed);

			function onFailed(error) {
				console.log('XHR Failed for addJobType.' + error);
			}
		}

		function editJobType(jobTypeData) {
			let url = APIConfigService.url('jobtypes', jobTypeData.jobtypeId);
			return $http.put(url, jobTypeData )
			.then(function (response) {
				return response.data;
			})
			.catch(onFailed);

			function onFailed(error) {
				console.log('XHR Failed for editJobType.' + error);
			}
		}

		function deleteJobType(jobtypeId) {
			let url = APIConfigService.url('jobtypes', jobtypeId);
			return $http.delete(url)
			.then(function (response) {
				return response;
			})
			.catch(onFailed);

			function onFailed(error) {
				console.log('XHR Failed for deleteJobType.' + error);
			}
		}
	}
})();