(function () {
	'use strict';
	angular.module('roi.jobtypes').controller('JobTypeController', JobTypeController);

	JobTypeController.$inject = ['$scope', '$state', '$stateParams', 'JobTypeService', 'StaticDataService', 'Utilities', 'toastr'];

	function JobTypeController($scope, $state, $stateParams, JobTypeService, StaticDataService, Utilities, toastr) {
		let self = this;
		self.jobtype = null;
		let jobtypes = [];
		self.modelform = {};
		self.model = {
			jobtype: null
		};

		self.discardChanges = discardChanges;
		self.submitChanges = submitChanges;

		init();

		function init() {
			JobTypeService.getJobTypes().then(function(list){
				jobtypes = list;
				getJobType($stateParams.jobtypeId);
			});
		}

		function getJobType(jobtypeId) {
			let jobtype = _.find(jobtypes, { jobtypeId: parseInt(jobtypeId) });
			self.jobtype = angular.copy(jobtype);
			self.model.jobtype = angular.copy(jobtype);
		}

		function submitChanges() {
			let submittedData = angular.copy(self.model.jobtype),
				jobtypeData = Utilities.filterChanges(self.jobtype, submittedData);
			if (_.isEmpty(jobtypeData)) {
				toastr.warning('No jobtype data have been changed', 'Warning');
				return false;
			}
			jobtypeData.jobtypeId = self.model.jobtype.jobtypeId;
			editJobType(jobtypeData);
		}

		function discardChanges() {
			self.model.jobtype = angular.copy(self.jobtype);
		}

		function editJobType(jobtypeData) {
			return JobTypeService.editJobType(jobtypeData).then(function (response) {
				console.log('JobType has been changed to ', jobtypeData);
				if (response.err === false) {
					toastr.success('JobType data have been changed', 'Success');
					$state.go('app.jobtypes.list');
				} else {
					toastr.error('JobType data have not been changed', 'Error');
				}
			}).catch(function (error) {
				console.error(error);
			});
		}
	}
})();
