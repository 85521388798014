(function () {
	'use strict';
	angular.module('roi.app').factory('InstructionService', InstructionService);

	InstructionService.$inject = ['$http', '$location', '$q', '$log', 'APIConfigService'];

	function InstructionService($http, $location, $q, $log, APIConfigService) {

		return {
			getInstructions: getInstructions,
			getInstruction: getInstruction,
			addInstruction: addInstruction,
			editInstruction: editInstruction,
			deleteInstruction: deleteInstruction
		};

		function getInstructions() {
			let url = APIConfigService.url( 'config' );
			return $http.get( url )
			.then( onComplete )
			.catch( onFailed );

			function onComplete(response) {
				return response.data;
			}

			function onFailed(error) {
				console.log( 'XHR Failed for getInstructions.' + error );
			}
		}
		
		function getInstruction(instructionId) {
			let url = APIConfigService.url('config', instructionId);
			return $http.get(url)
			.then(onComplete)
			.catch(onFailed);

			function onComplete(response) {
				return response.data;
			}

			function onFailed(error) {
				console.log('XHR Failed for getInstructions.' + error);
			}
		}
		
		function addInstruction(instructionData) {
			let url = APIConfigService.url('config');
			return $http.post(url, instructionData)
			.then(function (response) {
				return response.data;
			})
			.catch(onFailed);

			function onFailed(error) {
				console.log('XHR Failed for addInstruction.' + error.data);
			}
		}

		function editInstruction(instructionData) {
			let url = APIConfigService.url('config', instructionData.configId);
			return $http.put(url, instructionData)
			.then(function (response) {
				return response.data;
			})
			.catch(onFailed);

			function onFailed(error) {
				console.log('XHR Failed for editInstruction.' + error.data);
			}
		}

		function deleteInstruction(instructionId) {
			let url = APIConfigService.url('config', instructionId);
			return $http.delete(url)
			.then(function (response) {
				return response.data;
			})
			.catch(onFailed);

			function onFailed(error) {
				console.log('XHR Failed for deleteInstruction.' + error.data);
			}
		}
	}
})();
