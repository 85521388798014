(function () {
	'use strict';
	angular.module('roi.app').factory('UserService', UserService);

	UserService.$inject = ['$http', '$location', '$q', '$log', 'APIConfigService','StaticDataService'];

	function UserService($http, $location, $q, $log, APIConfigService, StaticDataService) {

		let fileurl = APIConfigService.fileUrl();
		
		return {
			getUsers: getUsers,
			getUser: getUser,
			addUser: addUser,
			editUser: editUser,
			deleteUser: deleteUser
		};
		
		function getUsers(params) {
			let url = APIConfigService.url('users',null,null,params);
			return $http.get(url)
			.then(function (response) {
				return response.data;
			})
			.then(function (users) {
				return applyStaticData(users);
			})
			.catch(onFailed);

			function onFailed(error) {
				console.error('XHR Failed for getUsers.',  error);
			}
		}

		function getUser(userId) {
			let url = APIConfigService.url('users',userId,null,null);
			return $http.get(url)
			.then(function (response) {
				return applyStaticData([response.data]);
			}).then(function (users) {
				return users[0];
			})
			.catch(onFailed);

			function onFailed(error) {
				console.log('XHR Failed for getUser.',  error);
			}
		}

		function addUser(userData) {
			let url = APIConfigService.url('users',null,null,null);
			return $http.post(url, userData )
			.then(function (response) {
				return response.data;
			})
			.catch(onFailed);

			function onFailed(error) {
				console.log('XHR Failed for addUser.',  error);
			}
		}

		function editUser(userData) {
			let url = APIConfigService.url('users',userData.userId,null,null);
			return $http.put(url, userData)
			.then(function (response) {
				return response.data;
			})
			.catch(onFailed);

			function onFailed(error) {
				console.log('XHR Failed for editUser.', error);
			}
		}

		function deleteUser(userId) {
			let url = APIConfigService.url('users',userId,null,null);
			return $http.delete(url)
			.then(function (response) {
				return response.data;
			})
			.catch(onFailed);

			function onFailed(error) {
				console.log('XHR Failed for deleteUser.',  error);
			}
		}

		function applyStaticData(users) {
			users.forEach(function(user) {
				user.chargeInUSD = (user.chargeInUSD === 1);
			});
			return StaticDataService.getStaticData().then(function(listData){
				users = groupsToUsers(users, listData.groups);
				users = currencyToUsers(users, listData.currencies);
				return users;
			});
		}

		function groupsToUsers(users, ugroups) {
			return _.each(users, function (user) {
				user.ugroupName = [];
				if (angular.isArray(user.groups) && user.groups.length > 0) {
					user.groups.forEach(function(group) {
						let ugroup = _.find( ugroups, { ugroupId: group } );
						if(ugroup && ugroup.ugroupId) {
							user.ugroupName.push(ugroup.ugroupName);
						}
					});
				} else {
					user.ugroupName.push('Not Assigned');
				}

				user.ugroupName = user.ugroupName.join(', ');
			});
		}
		
		function currencyToUsers(users, currencies) {
			return _.each(users, function (user) {
				user.currencyName = 'Not Assigned';
				let currency = _.find(currencies, { currencyId: user.currencyId });
				if (currency && currency.currencyId) {
					user.currencyName = currency.name;
				}
			});
		}
	}
})();
