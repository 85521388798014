(function() {
	'use strict';
	angular.module( 'roi.app' )
	.factory( 'SessionService', SessionService )
	.factory( 'authHttpInterceptor', tokenHttpInterceptor )
	;

	SessionService.$inject = ['$rootScope', '$http', '$location', '$q', '$log', '$state', 'APIConfigService', 'StaticDataService', 'UserService'];

	function SessionService($rootScope, $http, $location, $q, $log, $state, APIConfigService, StaticDataService, UserService) {

		let user = undefined, userId = undefined;
		return {
			authenticate: authenticate,
			getUser: getUser,
			login: login,
			logout: logout,
			changepass: changepass
		};

		function authenticate() {
			console.log("authenticate");
			let url = APIConfigService.url( 'session' );
			return $http.get( url ).then( function(response) {
				if(!response.data.err) {
					if(userId === response.data.userId) {
						return user;
					}
					userId = undefined;
					user = UserService.getUser( response.data.userId ).then( function(user) {
						userId = user.userId;
						user.ugroupId = 0;
						if(user.groups && user.groups.length > 0)
							user.ugroupId = user.groups[0];
						if(user.balance) {
							user.balance = parseFloat(user.balance);
						}
						if(user.creditLimit){
							user.creditLimit = parseFloat(user.creditLimit);
						}
						return user;
					} );
					console.log('authenticate, return user');
					return user;
				}
				return $state.go('applogin');
			} );
		}

		function getUser() {
			return user;
		}

		function login(username, password) {
			let url = APIConfigService.url( 'session' );
			return $http.post( url, { username: username, password: password } ).then( function(response) {
				return response.data;
			} );
		}

		function logout() {
			let url = APIConfigService.url( 'session' );
			$rootScope.$broadcast('userlogout');
			return $http.delete( url );
		}

		function changepass(activekey, password, newpassword, newpassword2) {
			let url = APIConfigService.url( 'session' );
			return $http.put( url, { activekey: activekey, password: password, newpassword: newpassword, newpassword2: newpassword2 } )
			.then( function(response) {
				return response.data;
			} )
			.catch( function(response) {
				return response.data;
			} );
		}
	}

	tokenHttpInterceptor.$inject = ['$injector', '$q', '$window'];

	function tokenHttpInterceptor($injector, $q, $window) {
		let UserService = undefined;    //cannot inject directly, circular dependency
		let $state = undefined;
		return {
			'responseError': function(response) {
				console.error( 'interceptor error' );
				if(response.config && response.config.url && response.config.url.startsWith( '/restapi' )) {
					if(response.status === 401) {
						let state = getState();
						if(state.current.name === 'applogin') {
							return;
						}
						state.go( 'applogin', { reload: true } ).then( function() {
							//$window.location.reload();
						} );
					}
				}
				return $q.reject(response);
			}
		};

		function getState() {
			if($state) {
				return $state;
			}
			$state = $injector.get( '$state' );
			return $state;
		}
	}
})();
