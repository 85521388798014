(function () {
	'use strict';
	angular.module('roi.taxes').controller('NewTaxController', NewTaxController);

	NewTaxController.$inject = ['$scope', '$state', 'TaxService', 'StaticDataService', 'toastr'];

	function NewTaxController($scope, $state, TaxService, StaticDataService, toastr) {
		let self = this;
		self.tax = null;

		self.modelform = {};
		self.model = {
			tax: null
		};

		self.submitChanges = submitChanges;

		function submitChanges() {
			let submittedData = angular.copy(self.model.tax);
			submittedData.rate = parseFloat(submittedData.rate);
			addTax(submittedData);
		}

		function addTax(taxData) {
			return TaxService.addTax(taxData).then(function (response) {
				console.log('Tax has been added ', taxData);
				if (response.err === false) {
					toastr.success('Tax data have been added '+response.id, 'Success');
					$state.go('app.taxes.list');
				} else {
					toastr.error('Tax data have not been added - '+response.msg, 'Error');
				}
				return null;
			}).catch(function (error) {
				console.error(error);
			});
		}
	}
})();
