(function () {
	'use strict';
	angular.module('roi.instructions').controller('NewInstructionController', NewInstructionController);

	NewInstructionController.$inject = ['$scope', '$state', 'InstructionService', 'StaticDataService', 'toastr'];

	function NewInstructionController($scope, $state, InstructionService, StaticDataService, toastr) {
		let self = this;
		self.instruction = null;
		self.staticData = StaticDataService.getStaticData();

		self.modelform = {};
		self.model = {
			instruction: null
		};

		self.submitChanges = submitChanges;

		function submitChanges() {
			let submittedData = angular.copy(self.model.instruction);
			addInstruction(submittedData);
		}

		function addInstruction(instructionData) {
			return InstructionService.addInstruction(instructionData).then(function (response) {
				console.log('Instruction has been added ', instructionData);
				if (response.err === false) {
					toastr.success('Instruction data have been added', 'Success');
					$state.go('app.instructions.list');
				} else {
					toastr.error('Instruction data have not been added', 'Error');
				}
			}).catch(function (error) {
				console.error(error);
			});
		}
	}
})();
