(function () {
	'use strict';
	angular.module('roi.users')
	.config(['toastrConfig','$stateProvider', function (toastrConfig, $stateProvider) {
		angular.extend(toastrConfig, {
			autoDismiss: false
		});
		$stateProvider.state('app.users', {
			url: '/users',
			abstract: true,
			ncyBreadcrumb: {
				skip: true
			},
			template: '<ui-view></ui-view>'
		});
		$stateProvider.state('app.users.list', {
			url: '/list',
			ncyBreadcrumb: {
				label: 'User List'
			},
			controller: 'UserListController',
			controllerAs: 'uc',
			templateUrl: './js/modules/users/user-list.html'
		});
		$stateProvider.state('app.users.user', {
			url: '/user/:userId',
			ncyBreadcrumb: {
				parent: 'app.users.list',
				label: 'User'
			},
			controller: 'UserController',
			controllerAs: 'uc',
			templateUrl: './js/modules/users/user.html'
		});
		$stateProvider.state('app.users.new', {
			url: '/user/new',
			ncyBreadcrumb: {
				parent: 'app.users.list',
				label: 'Add New User'
			},
			controller: 'NewUserController',
			controllerAs: 'uc',
			templateUrl: './js/modules/users/user-new.html'
		});
	}]);
})();