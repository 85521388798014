(function () {
	'use strict';
	angular.module('roi.users').controller('NewNotificationController', NewNotificationController);

	NewNotificationController.$inject = ['$scope', '$state', 'NotificationService', 'UserService', 'StaticDataService', 'toastr'];

	function NewNotificationController($scope, $state, NotificationService, UserService, StaticDataService, toastr) {
		let self = this;

		self.modelform = {};
		self.model = {
			notification: {},
			recipientType: 1
		};

		let uiGroup = {
			'ugroupId': 0,
			'ugroupName': 'Show to all'
		};

		self.ui = {
			groups: [],
			types: [],
			users: []
		};

		self.submitChanges = submitChanges;

		self.endDateBeforeRender = endDateBeforeRender;
		self.endDateOnSetTime = endDateOnSetTime;
		self.startDateBeforeRender = startDateBeforeRender;
		self.startDateOnSetTime = startDateOnSetTime;

		init();

		function init() {
			StaticDataService.getStaticData().then(function (listData) {
				self.ui.groups = _.union([uiGroup], listData.groups);
				self.ui.types = listData.notificationtypes;
				getUsers();
			});
		}

		function getUsers() {
			self.ui.users.length = 0;
			return UserService.getUsers().then(function (users) {
				self.model.recipientType = 1;
				users.forEach(function (user) {
					self.ui.users.push(user);
				});
				return users;
			}).catch(function (error) {
				console.error(error);
			});
		}

		function submitChanges() {
			let submittedData = angular.copy(self.model.notification);
			if (self.model.recipientType === 1) {
				submittedData.userId = parseInt(submittedData.user.userId);
				delete submittedData.user;
				delete submittedData.group;
				delete submittedData.ugroupId;
			} else if (self.recipientType === 2) {
				submittedData.ugroupId = (submittedData.ugroupId) ? parseInt(submittedData.ugroupId) : 0;
				delete submittedData.user;
				delete submittedData.userId;
				delete submittedData.group;
			}

			if (_.isEmpty(submittedData)) {
				toastr.warning('No notification data have been changed', 'Warning');
				return false;
			}
			addNotification(submittedData);
		}

		function startDateOnSetTime() {
			$scope.$broadcast('start-date-changed');
		}

		function endDateOnSetTime() {
			$scope.$broadcast('end-date-changed');
		}

		function startDateBeforeRender($dates) {
			if (self.model.notification && self.model.notification.endDate) {
				let activeDate = moment(self.model.notification.endDate);

				$dates.filter(function (date) {
					return date.localDateValue() >= activeDate.valueOf();
				}).forEach(function (date) {
					date.selectable = false;
				});
			}
		}

		function endDateBeforeRender($view, $dates) {
			if (self.model.notification && self.model.notification.startDate) {
				let activeDate = moment(self.model.notification.startDate).subtract(1, $view).add(1, 'minute');

				$dates.filter(function (date) {
					return date.localDateValue() <= activeDate.valueOf();
				}).forEach(function (date) {
					date.selectable = false;
				});
			}
		}

		function addNotification(notificationData) {
			return NotificationService.addNotification(notificationData).then(function (response) {
				console.log('Notification has been added ', notificationData);
				if (response.err === true) {
					toastr.success('Notification data have been added', 'Success');
					$state.go('app.notifications.list');
				} else {
					toastr.error('Notification data have not been added, '+response.msg, 'Error');
				}
			}).catch(function (error) {
				console.error(error);
			});
		}
	}
})();
