(function () {
	'use strict';
	angular.module('roi.statuses').controller('StatusListController', StatusListController);

	StatusListController.$inject = ['$scope', '$state', '$uibModal', 'StatusService', 'StaticDataService', 'toastr'];

	function StatusListController($scope, $state, $uibModal, StatusService, StaticDataService, toastr) {
		let self = this;
		self.selectedStatus = null;

		self.editStatus = editStatus;
		self.deleteStatus = deleteStatus;
		self.confirm = confirm;
		self.cancel = cancel;

		init();

		function init() {
			StaticDataService.getStaticData().then(function(listData){
				self.statuses = angular.copy(listData.statuses);
				self.statusTable = angular.copy(listData.statuses);
			});
		}

		function editStatus(statusId) {
			$state.go('app.statuses.status', { statusId: statusId });
		}

		function deleteStatus(status) {
			self.selectedStatus = status;
			self.modalInstance = $uibModal.open({
				animation: true,
				scope: $scope,
				ariaLabelledBy: 'modal-title',
				ariaDescribedBy: 'modal-body',
				templateUrl: 'deletestatus.html',
				size: 'sm',
				resolve: {}
			});
			self.modalInstance.result.then(function (response) {
				return StatusService.deleteStatus(self.selectedStatus.jobstatusId).then(function (response) {
					if (response.data === 'ok') {
						toastr.success('Status has been deleted', 'Success');
						removeItem(status);
					} else {
						toastr.error('Status has not been deleted', 'Error');
					}
					self.selectedStatus = null;
				}).catch(function (error) {
					self.selectedStatus = null;
					console.error(error);
				});
			}, function () {
				self.selectedStatus = null;
			});
		}

		function confirm() {
			self.modalInstance.close();
		}

		function cancel() {
			self.modalInstance.dismiss();
		}

		// table functions
		function removeItem(row) {
			self.statuses = _.filter(self.statuses, function (o) {
				return o.statusId !== row.statusId;
			});
		}

	}
})();