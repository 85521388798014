(function () {
	'use strict';
	angular.module('roi.jobtypes').controller('NewJobTypeController', NewJobTypeController);

	NewJobTypeController.$inject = ['$scope', 'JobTypeService', 'StaticDataService', 'toastr'];

	function NewJobTypeController($scope, JobTypeService, StaticDataService, toastr) {
		let self = this;
		self.jobtype = null;
		self.staticData = StaticDataService.getStaticData();

		self.modelform = {};
		self.model = {
			jobtype: null
		};

		self.submitChanges = submitChanges;

		function submitChanges() {
			let submittedData = angular.copy(self.model.jobtype);
			addJobType(submittedData);
		}

		function addJobType(jobtypeData) {
			return JobTypeService.addJobType(jobtypeData).then(function (response) {
				console.log('JobType has been added ', jobtypeData);
				if (response.data === 'ok') {
					toastr.success('JobType data have been added', 'Success');
				} else {
					toastr.error('JobType data have not been added', 'Error');
				}
			}).catch(function (error) {
				console.error(error);
			});
		}
	}
})();
