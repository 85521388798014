(function () {
	'use strict';
	angular.module('roi.app').factory('JobService', JobService);

	JobService.$inject = ['$http', '$location', '$q', '$log', 'UserService', 'StaticDataService', 'APIConfigService'];

	function JobService($http, $location, $q, $log, UserService, StaticDataService, APIConfigService) {

		return {
			getJobs: getJobs,
			getJob: getJob,
			addJob: addJob,
			editJob: editJob,
			deleteJob: deleteJob
		};

		function getJobs(user, params) {
			let data = undefined;
			let url = APIConfigService.url('jobs', null, null, params);

			return $http.get(url)
			.then(function (response) {
				if(undefined !== user) {
					if(-1 !== user.groups.indexOf(3)) {
						response.data = _.filter(response.data, {userId: user.userId});
					}
					if(-1 !== user.groups.indexOf(2)) {
						response.data = _.filter(response.data, {assigneeId: user.userId});
					}
				}

				console.log(response.data);
				return applyData(response.data);
			}).catch(onFailed);

			function onFailed(error) {
				console.error('XHR Failed for getJobs.', error);
			}
		}

		function getJob(jobId) {
			let job;
			jobId = parseInt(jobId);
			let url = APIConfigService.url('jobs', jobId);
			return $http.get(url)
			.then(function (response) {
				return applyData([response.data]);
			}).then(function (jobs) {
				return jobs[0];
			})
			.catch(onFailed);

			function onFailed(error) {
				console.log('XHR Failed for getJob.', error);
			}
		}

		function addJob(jobData) {
			let url = APIConfigService.url('jobs');
			return $http.post(url, jobData)
			.then(function (response) {
				return response.data;
			})
			.catch(onFailed);

			function onFailed(error) {
				console.log('XHR Failed for addJob.', error);
				return { err: true, msg: error.statusText};
			}
		}

		function editJob(jobData) {
			let url = APIConfigService.url('jobs', jobData.jobId);
			return $http.put(url, jobData)
			.then(function (response) {
				console.log(response);
				return response.data;
			})
			.catch(onFailed);

			function onFailed(error) {
				console.log('XHR Failed for editJob.', error);
				return { err: true, msg: error.statusText};
			}
		}

		function deleteJob(jobId) {
			let url = APIConfigService.url('jobs', jobId);
			return $http.delete(url)
			.then(function (response) {
				return response.data;
			})
			.catch(onFailed);

			function onFailed(error) {
				console.log('XHR Failed for deleteJob.', error);
			}
		}

		function applyData(jobs) {
			return StaticDataService.getStaticData()
			.then(function (listData) {
				let userMap = {};
				jobs.forEach(function (job) {
					userMap[job.userId] = true;
					userMap[job.assigneeId] = true;
					job.status = _.find(listData.statuses, { jobstatusId: job.jobstatusId });
					job.jobtype = _.find(listData.jobtypes, { jobtypeId: job.jobtypeId });
					let joboptions = _.filter(listData.joboptions, { jobtypeId: job.jobtypeId });
					job.options = assignJobOptions(job.options, joboptions);

					if (job.filenamesFinal && job.filenamesFinal.length > 0) {
						job.filenamesFinal.forEach(function (filename) {
							filename.icon = assignIcon(filename);
						});
					}
					if (job.filenamesUpload && job.filenamesUpload.length > 0) {
						job.filenamesUpload.forEach(function (filename) {
							filename.icon = assignIcon(filename);
						});
					}
				});
				//load relevant users
				let userPromises = [];
				Object.getOwnPropertyNames(userMap).forEach(function (userId) {
					userPromises.push(UserService.getUser(userId));
				});
				return $q.all(userPromises);
			}).then(function (users) {
				let userMap = {};
				users.forEach(function (user) {
					userMap[user.userId] = user;
				});
				jobs.forEach(function (job) {
					job.user = userMap[job.userId];
					job.assignee = userMap[job.assigneeId];
				});
				return jobs;
			}).catch(onFailed);

			function onFailed(error) {
				console.error('XHR Failed for applyDataForJobs.', error);
			}
		}

		function assignJobOptions(option, joboptions) {
			let options = '';
			option = JSON.parse(option);
			joboptions.forEach(function(joboption) {
				if(undefined !== option[joboption.joboptionType]) {
					let tempJoboption = _.find(joboption.options, {value: option[joboption.joboptionType]});
					tempJoboption.label = joboption.joboptionLabel;
					options += '<strong>' + tempJoboption.label + '</strong>:<br>'+ tempJoboption.desc +'<br>';
				}
			});
			return options;
		}
		function assignIcon(filename) {
			let extensions = {
					image: ['jpg', 'jpeg', 'gif', 'png'],
					icons: ['pdf', 'bmp', 'zip', 'doc', 'docx']
				},
				ext = filename.filename.split('.').pop();
			filename.icon = null;
			if (extensions.image.indexOf(ext) === -1) {
				filename.icon = 'fa-file-image-o';
				if (extensions.icons.indexOf(ext) !== -1) {
					filename.icon = 'fa-file-image-o';
					switch (ext) {
						case 'pdf':
							filename.icon = 'fa-file-pdf-o';
							break;
						case 'bmp':
							filename.icon = 'fa-file-image-o';
							break;
						case 'zip':
							filename.icon = 'fa-file-archive-o';
							break;
						case 'doc':
						case 'docx':
							filename.icon = 'fa-file-word-o';
							break;
					}
				}
			}
			return filename.icon;
		}
	}
})();
