(function () {
	'use strict';
	angular.module('roi.transactiontypes').controller('TransactiontypesController', TransactiontypesController);

	TransactiontypesController.$inject = ['$scope', '$state', '$stateParams', 'TransactiontypeService', 'StaticDataService', 'Utilities', 'toastr'];

	function TransactiontypesController($scope, $state, $stateParams, TransactiontypeService, StaticDataService, Utilities, toastr) {
		let self = this;
		self.transactiontype = null;
		self.staticData = StaticDataService.getStaticData();

		self.modelform = {};
		self.model = {
			transactiontype: null
		};

		self.formUI = {
			regex: Utilities.regex
		};

		self.discardChanges = discardChanges;
		self.submitChanges = submitChanges;

		init();

		function init() {
			TransactiontypeService.getTransactiontypes().then(function(transactiontypes){
				let transactiontype = _.find(transactiontypes, { trId: parseInt($stateParams.trId) });
				self.transactiontype = angular.copy(transactiontype);
				self.model.transactiontype = angular.copy(transactiontype);
			});
		}

		function submitChanges() {
			let submittedData = angular.copy(self.model.transactiontype);
			let transactiontypeData = Utilities.filterChanges(self.transactiontype, submittedData);
			if (_.isEmpty(transactiontypeData)) {
				toastr.warning('No transaction type data have been changed', 'Warning');
				return false;
			}
			transactiontypeData.trId = self.model.transactiontype.trId;
			editTransactiontype(transactiontypeData);
		}

		function discardChanges() {
			self.model.transactiontype = angular.copy(self.transactiontype);
		}

		function editTransactiontype(transactiontypeData) {
			return TransactiontypeService.editTransactiontype(transactiontypeData).then(function (response) {
				console.log('Transaction type has been changed to ', transactiontypeData);
				if (response.err === false) {
					toastr.success('Transaction types data have been changed', 'Success');
					$state.go('app.transactiontypes.list');
				} else {
					toastr.error('Transaction types data have not been changed: '+response.msg, 'Error');
				}
			}).catch(function (error) {
				console.error(error);
			});
		}
	}
})();
