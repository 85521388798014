(function () {
	'use strict';
	angular.module('roi.instructions')
	.config(['$stateProvider', function ($stateProvider) {
		$stateProvider.state('app.instructions', {
			url: '/instructions',
			abstract: true,
			ncyBreadcrumb: {
				skip: true
			},
			template: '<ui-view></ui-view>'
		});
		$stateProvider.state('app.instructions.list', {
			url: '/list',
			ncyBreadcrumb: {
				label: 'Instructions'
			},
			controller: 'InstructionListController',
			controllerAs: 'tc',
			templateUrl: './js/modules/instructions/instruction-list.html'
		});
		$stateProvider.state('app.instructions.instruction', {
			url: '/instruction/:id',
			ncyBreadcrumb: {
				parent: 'app.instructions.list',
				label: 'Instruction'
			},
			controller: 'InstructionController',
			controllerAs: 'tc',
			templateUrl: './js/modules/instructions/instruction.html'
		});
		$stateProvider.state('app.instructions.new', {
			url: '/instruction/new',
			ncyBreadcrumb: {
				parent: 'app.instructions.list',
				label: 'Add New Instruction'
			},
			controller: 'NewInstructionController',
			controllerAs: 'tc',
			templateUrl: './js/modules/instructions/instruction-new.html'
		});
	}]);
})();
