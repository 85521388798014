(function() {
	'use strict';
	angular.module( 'roi.app' ).factory( 'APIConfigService', APIConfigService );

	APIConfigService.$inject = ['$location'];

	function APIConfigService($location) {
		let debugParam = false;
		if($location.host().indexOf( '.local' ) > 0 || $location.host().indexOf( 'localhost' ) >= 0) {
			debugParam = "XDEBUG_SESSION_START=VW$";
		}

		return {
			url: getUrl,
			fileUrl: getFileUrl
		};

		function getUrl(category, id, subcat, params) {
			let url = `/restapi/index.php?scat=${category}`;
			if(id) {
				url += `&id=${id}`;
			}
			if(subcat) {
				url += `&ssub=${subcat}`;
			}
			if(params) {
				for(let x in params) {
					if(params.hasOwnProperty( x )) {
						url += `&${x}=${params[x]}`;
					}
				}
			}
			if(debugParam)
				url += `&${debugParam}`;

			return url;
		}

		function getFileUrl() {
			let url = `/restapi/files.php`;
			if(debugParam)
				url += `?${debugParam}`;
			return url;
		}
	}
})();