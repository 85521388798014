(function () {
	'use strict';
	angular.module('roi.transactions').controller('InvoiceController', InvoiceController);

	InvoiceController.$inject = ['$scope', '$state', '$stateParams', '$q', 'toastr',
		'TransactionService', 'StaticDataService', 'APIConfigService', 'Utilities'];

	function InvoiceController($scope, $state, $stateParams, $q, toastr,
	                        TransactionService, StaticDataService, APIConfigService, Utilities) {
		let self = this;
		self.receipt = null;   //original data
		// TODO: check if invoice belongs to the owner OR admin

		init();

		function init() {
			TransactionService.getTransaction($stateParams.transactionId).then(function (transaction) {
				self.receipt = transaction[0];
				console.log(self.receipt);
			}).catch(function (error) {
				console.error(error);
			});
		}

	}
})();
