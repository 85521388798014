(function () {
	'use strict';
	angular.module('roi.app').factory('TransactiontypeService', TransactiontypeService);

	TransactiontypeService.$inject = ['$http', '$location', '$q', '$log', 'APIConfigService'];

	function TransactiontypeService($http, $location, $q, $log, APIConfigService) {

		let listPromise = undefined;
		return {
			getTransactiontypes: getTransactiontypes,
			addTransactiontype: addTransactiontype,
			editTransactiontype: editTransactiontype,
			deleteTransactiontype: deleteTransactiontype
		};

		function getTransactiontypes() {
			if(listPromise)
				return listPromise;
			let url = APIConfigService.url('transactiontypes');
			listPromise = $http.get(url)
			.then(onComplete)
			.catch(onFailed);

			return listPromise;
			function onComplete(response) {
				return response.data;
			}

			function onFailed(error) {
				console.log('XHR Failed for getTransactiontypes.' + error);
			}
		}

		function addTransactiontype(transactiontypeData) {
			let url = APIConfigService.url('transactiontypes');
			return $http.post(url, transactiontypeData)
			.then(function (response) {
				listPromise = undefined;
				return response.data;
			})
			.catch(onFailed);

			function onFailed(error) {
				console.log('XHR Failed for addTransactiontype.' + error);
			}
		}
		
		function editTransactiontype(transactiontypeData) {
			let url = APIConfigService.url('transactiontypes', transactiontypeData.trId);
			return $http.put(url, transactiontypeData)
			.then(function (response) {
				listPromise = undefined;
				return response.data;
			})
			.catch(onFailed);

			function onFailed(error) {
				console.log('XHR Failed for editTransactiontype.' + error);
			}
		}

		function deleteTransactiontype(trId) {
			let url = APIConfigService.url('transactiontypes', trId);
			return $http.delete(url)
			.then(function (response) {
				listPromise = undefined;
				return response.data;
			})
			.catch(onFailed);

			function onFailed(error) {
				console.log('XHR Failed for deleteTransactiontype.' + error);
			}
		}
	}
})();
