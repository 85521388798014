(function () {
	'use strict';
	angular.module('roi.users').controller('UserListController', UserListController);

	UserListController.$inject = ['$scope', '$state', '$uibModal', 'toastr', 'Utilities', 'UserService', 'StaticDataService'];

	function UserListController($scope, $state, $uibModal, toastr, Utilities, UserService, StaticDataService) {
		let self = this;
		self.users = [];
		self.userTable = [];
		self.selectedUser = null;

		//filters
		self.ui = {
			groups: []
		};

		self.removeItem = removeItem;
		self.editUser = editUser;
		self.deleteUser = deleteUser;
		self.blockUser = blockUser;
		self.confirm = confirm;
		self.cancel = cancel;

		init();

		function init() {
			getUsers();
			StaticDataService.getStaticData().then(function(listData){
				self.ui.groups = listData.groups;
			})
		}

		function getUsers() {
			self.users.length = 0;
			self.userTable.length = 0;
			return UserService.getUsers().then(function (users) {
				let groupsForSort = [1,2,3,4];
				users.sort(function(a,b){
					let aGroup = a.groups.length > 0 ? a.groups[0] : undefined;
					let bGroup = b.groups.length > 0 ? b.groups[0] : undefined;
					if(aGroup === bGroup) {
						return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
					}
					if(aGroup === undefined)
						return 1;
					if(bGroup === undefined)
						return -1;
					return groupsForSort.indexOf(aGroup) - groupsForSort.indexOf(bGroup);
				});
				users.forEach(function (user) {
					user.avatarFile = Utilities.getUserAvatarUrl(user);
					self.users.push(user);
					self.userTable.push(user);
				});
				return users;
			}).catch(function (error) {
				console.error(error);
			});
		}

		function editUser(userId) {
			$state.go('app.users.user', { userId: userId });
		}

		function deleteUser(user) {
			self.selectedUser = user;
			self.modalInstance = $uibModal.open({
				animation: true,
				scope: $scope,
				ariaLabelledBy: 'modal-title',
				ariaDescribedBy: 'modal-body',
				templateUrl: 'deleteuser.html',
				size: 'sm',
				resolve: {},
				windowClass: 'show',
				backdropClass: 'show'
			});
			self.modalInstance.result.then(function (response) {
				return UserService.deleteUser(self.selectedUser.userId).then(function (response) {
					if (response.err === false) {
						toastr.success('User has been deleted', 'Success');
						removeItem(user);
					} else {
						toastr.error('User has not been deleted', 'Error');
					}
					self.selectedUser = null;
				}).catch(function (error) {
					self.selectedUser = null;
					console.error(error);
				});
			}, function () {
				self.selectedUser = null;
			});
		}

		function blockUser(user, action) {
			self.selectedUser = user;
			self.modalInstance = $uibModal.open({
				animation: true,
				scope: $scope,
				ariaLabelledBy: 'modal-title',
				ariaDescribedBy: 'modal-body',
				templateUrl: 'blockuser.html',
				size: 'sm',
				resolve: {}
			});
			self.modalInstance.result.then(function (response) {
				return UserService.editUser( { userId: self.selectedUser.userId, block: true }).then(function (response) {
					if (response.err === false) {
						toastr.success('User has been blocked', 'Success');
					} else {
						toastr.error('User has not been blocked', 'Error');
					}
					self.selectedUser = null;
				}).catch(function (error) {
					self.selectedUser = null;
					console.error(error);
				});
			}, function () {
				self.selectedUser = null;
			});
		}

		function confirm() {
			self.modalInstance.close();
		}

		function cancel() {
			self.modalInstance.dismiss();
		}

		// table functions
		function removeItem(row) {
			let index = self.users.indexOf(row);
			if (index !== -1) {
				self.users.splice(index, 1);
			}
		}

	}
})();