(function () {
	'use strict';
	angular.module('roi.taxes')
	.config(['$stateProvider', function ($stateProvider) {
		$stateProvider.state('app.taxes', {
			url: '/taxes',
			abstract: true,
			ncyBreadcrumb: {
				skip: true
			},
			template: '<ui-view></ui-view>'
		});
		$stateProvider.state('app.taxes.list', {
			url: '/list',
			ncyBreadcrumb: {
				label: 'Taxes'
			},
			controller: 'TaxListController',
			controllerAs: 'tc',
			templateUrl: './js/modules/taxes/tax-list.html'
		});
		$stateProvider.state('app.taxes.tax', {
			url: '/tax/:taxId',
			ncyBreadcrumb: {
				parent: 'app.taxes.list',
				label: 'Tax'
			},
			controller: 'TaxController',
			controllerAs: 'tc',
			templateUrl: './js/modules/taxes/tax.html'
		});
		$stateProvider.state('app.taxes.new', {
			url: '/tax/new',
			ncyBreadcrumb: {
				parent: 'app.taxes.list',
				label: 'Add New Tax'
			},
			controller: 'NewTaxController',
			controllerAs: 'tc',
			templateUrl: './js/modules/taxes/tax-new.html'
		});
	}]);
})();