(function() {
	'use strict';
	angular.module( 'roi.users' ).controller( 'LoginController', LoginController );

	LoginController.$inject = ['$scope', '$state', '$stateParams', '$q', 'Upload', 'toastr',
		'JobService', 'UserService', 'StaticDataService', 'APIConfigService', 'Utilities', 'SessionService'];

	function LoginController($scope, $state, $stateParams, $q, Upload, toastr,
	                         JobService, UserService, StaticDataService, APIConfigService, Utilities, SessionService) {
		let self = this;
		self.modelform = {};
		self.formdata = {
			username: '',
			password: 'Granite'
		};

		self.formUI = {};
		self.formUI.regex = Utilities.regex;

		self.login = login;
		self.logout = logout;
		self.discardChanges = discardChanges;

		init();

		function init() {
		}

		function login() {
			SessionService.login( self.formdata.username, self.formdata.password ).then( function(response) {
				if(response.err === true) {
					toastr.error( response.msg ? response.msg : 'Unknown error' );
					return;
				}
				$state.go( 'app.dashboard' );
			} ).catch( function(error) {
				toastr.error( error.msg ? error.msg : 'Unknown error' );
			} )
		}

		function logout() {
			SessionService.logout().then( function() {
			} ).catch( function(error) {
				toastr.error( error.msg ? error.msg : 'Unknown error' );
			} )
		}

		function discardChanges() {
			self.modelform.$pristine = true;
		}
	}
})();
