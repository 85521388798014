(function () {
	'use strict';
	angular.module('roi.jobs').controller('JobListController', JobListController);

	JobListController.$inject = ['$scope', '$q', '$location', '$state', '$uibModal', 'JobService', 'UserService', 'StaticDataService', 'toastr'];

	function JobListController($scope, $q, $location, $state, $uibModal, JobService, UserService, StaticDataService, toastr) {
		let self = this;
		self.curUser = $scope.curUser;

		self.jobs = [];
		self.usersWithJobs = [];
		self.assigneesWithJobs = [];
		self.selectedJob = null;
		self.jobTable = [];
		self.model = {
			job: []
		};

		self.awsStorage = 'storage';
		if($location.host().indexOf( '.local' ) > 0 || $location.host().indexOf( 'localhost' ) >= 0) {
			self.awsStorage = 'storage-local';
		}
		if($location.host().indexOf( 'demo' ) >= 0) {
			self.awsStorage = 'storage-stage';
		}

		let uiJobTypes = {
			'jobtypeId': '',
			'jobtypeName': 'Show All Job Types'
		};
		let uiJobstatuses = [{
			'jobstatusId': -1,
			'jobstatusName': 'All Statuses'
		},{
			'jobstatusId': -6,
			'jobstatusName': 'All But Completed'
		}];
		let uiAssignees = {
			'userId': '',
			'name': 'Show All Assignees'
		};
		let uiUsers = {
			'userId': '',
			'name': 'Show All Clients'
		};

		self.selectedRows = [];
		self.ui = {
			jobtypes: [],
			assignees: [uiAssignees],
			users: [uiUsers],
			jobstatuses: [].concat(uiJobstatuses)
		};

		self.retrieveList = retrieveList;
		self.removeItem = removeItem;
		self.editJob = editJob;
		self.deleteJob = deleteJob;
		self.changeAssignee = changeAssignee;
		self.confirm = confirm;
		self.cancel = cancel;

		init();

		function init() {
		}
		
		function retrieveList(tableState) {
			self.jobs.length = 0;
			self.jobTable.length = 0;
			let params = {};
			if(tableState && tableState.search && tableState.search.predicateObject) {
				if(tableState.search.predicateObject.jobstatusId) {
					params.jobstatusId = tableState.search.predicateObject.jobstatusId;
				}
				if(tableState.search.predicateObject.jobId) {
					params.jobId = tableState.search.predicateObject.jobId;
				}
			}
			$q.all({
				jobs: JobService.getJobs(self.curUser, params),
				listData: StaticDataService.getStaticData()
			}).then(function (data) {

				self.ui.jobtypes = _.union([uiJobTypes], data.listData.jobtypes);
				self.ui.jobstatuses = _.union(uiJobstatuses, data.listData.statuses);
				data.jobs.forEach(function (job) {
					self.jobs.push(job);
					self.jobTable.push(job);
				});
				let uniqUsers = _.uniqBy(_.map(data.jobs, 'user'), 'userId');
				let uniqAssignees = _.uniqBy(_.map(data.jobs, 'assignee'), 'userId');
				self.ui.users.length = 0;
				self.ui.assignees.length = 0;
				uniqUsers.forEach(function (uu) {
					self.ui.users.push(uu);
				});
				uniqAssignees.forEach(function (ua) {
					self.ui.assignees.push(ua);
				});
			});
		}

		function editJob(jobId) {
			$state.go('app.jobs.job', { jobId: jobId });
		}

		function deleteJob(job) {
			self.selectedJob = job;
			self.modalInstance = $uibModal.open({
				animation: true,
				scope: $scope,
				ariaLabelledBy: 'modal-title',
				ariaDescribedBy: 'modal-body',
				templateUrl: 'deletejob.html',
				size: 'sm',
				resolve: {},
				windowClass: 'show',
				backdropClass: 'show'
			});
			self.modalInstance.result.then(function () {
				return JobService.deleteJob(self.selectedJob.jobId).then(function (response) {
					if (response.err === false) {
						toastr.success('Job #' + response.id + ' has been deleted', 'Success');
						removeItem(job);
					} else {
						toastr.error('Job has not been deleted. Server says: ' + response.msg, 'Error');
						console.error(response.msg);
					}
					self.selectedJob = null;
				}).catch(function (error) {
					self.selectedJob = null;
					console.error(error);
				});
			}, function () {
				self.selectedJob = null;
			});
		}

		function changeAssignee(job) {
			self.selectedRows[job.jobId] = {
				assignee: job.assigneeId
			};
			self.model.job[job.jobId] = job;
			console.log(job);
		}

		function confirm() {
			self.modalInstance.close();
		}

		function cancel() {
			self.modalInstance.dismiss();
		}

		// table functions
		function removeItem(row) {
			let index = self.jobs.indexOf(row);
			if (index !== -1) {
				self.jobs.splice(index, 1);
				self.jobTable.splice(index, 1);
			}
		}

	}
})();