(function () {
	'use strict';
	angular.module('roi.jobs').controller('JobController', JobController);

	JobController.$inject = ['$scope', '$stateParams', '$window', '$q', '$location', '$anchorScroll', 'Upload', 'toastr',
		'JobService', 'UserService', 'StaticDataService', 'APIConfigService', 'Utilities'];

	function JobController($scope, $stateParams, $window, $q, $location, $anchorScroll, Upload, toastr,
	                       JobService, UserService, StaticDataService, APIConfigService, Utilities) {
		let self = this;

		self.job = null;
		self.act = null;
		self.modelform = {
			jobActionId: null
		};
		self.model = {
			job: null
		};

		self.actions = [];
		self.formUI = {
			assignees: []
		};

		self.awsStorage = 'storage';
		if($location.host().indexOf( '.local' ) > 0 || $location.host().indexOf( 'localhost' ) >= 0) {
			self.awsStorage = 'storage-local';
		}
		if($location.host().indexOf( 'demo' ) >= 0) {
			self.awsStorage = 'storage-stage';
		}

		self.formUI.regex = Utilities.regex;
		self.formUI.image = [];
		self.getJob = getJob;
		self.addFiles = addFiles;
		self.uploadFiles = uploadFiles;
		self.doAction = doAction;
		self.openImage = openImage;
		self.removeImage = removeImage;
		self.discardChanges = discardChanges;
		self.submitChanges = submitChanges;

		init();

		function init() {
			$q.all({
				job: JobService.getJob(parseInt($stateParams.jobId)),
				assignees: UserService.getUsers({ ugroupId: 2 }),
				listData: StaticDataService.getStaticData()
			}).then(function (data) {
				self.formUI.assignees = angular.copy(data.assignees);
				return $q.all({
					job: data.job,
					listData: data.listData,
					user: UserService.getUser(data.job.userId),
					assignee: UserService.getUser(data.job.assigneeId)
				});
			}).then(function (data) {
				let job = data.job;
				job.status = _.find(data.listData.statuses, { jobstatusId: job.jobstatusId });
				job.actions = getWorkFlow(data.listData, job, self.$resolve.curUser);
				job.action = null;
				job.user = data.user;
				job.assignee = data.assignee;
				self.model.job = job;
				self.job = angular.copy(job);
			});
			self.modelform.$pristine = true;
		}

		function getJob(jobId) {
			return JobService.getJob(jobId).then(function (job) {
				self.model.job = angular.copy(job);
				self.job = angular.copy(self.model.job);
				return job;
			}).catch(function (error) {
				console.error(error);
			});
		}

		function doAction(action) {
			self.model.job.action = action;
			switch (parseInt(action.jobactionId)) {
				case 4:
				case 8:
				case 21:
				case 23:
					self.model.job.action.showForm = true;
					self.modelform.$pristine = true;
					scrollToAction('options');
					break;
				default:
					self.model.job.action.showForm = false;
					self.modelform.$pristine = false;
					scrollToAction('conversation');
					break;
			}
		}

		function scrollToAction(act) {
			let newHash = 'act-' + act;
			if ($location.hash() !== newHash) {
				$location.hash('act-' + act);
			} else {
				$anchorScroll();
			}
		}

		function getWorkFlow(staticData, job, curUser) {
			let workflow = _.find(staticData.jobtypes, { jobtypeId: job.jobtypeId });
			let statuses = [];
			let wfStatuses = workflow.groups[curUser.ugroupId];
			if(wfStatuses) {
			for(let wfStatusId in wfStatuses) {
				if (parseInt(wfStatusId) === job.jobstatusId) {
					//statuses = o[job.statusId];
					workflow.actions = _.filter(staticData.actions, function (o) {
						return (_.indexOf(wfStatuses[wfStatusId], parseInt(o.jobactionId)) > -1);
					});
				}
			}
			}
			return workflow.actions;
		}

		function openImage(index) {
			$window.open(self.job.imgs.final[index], '_blank');
		}

		function removeImage(imageIndex) {
			if (imageIndex !== -1) {
				self.model.job.files.splice(imageIndex, 1);
				console.log(self.model.job.files);
			}
		}

		function addFiles(files, errFiles) {
			if (errFiles && errFiles.length > 0) {
				errFiles.forEach(function (errfile) {
					let errReason = 'Wrong file format.';
					switch (errfile.$error) {
						case 'maxFiles':
							errReason = 'Too many files, only ' + errfile.$errorParam + ' are allowed.';
							break;
					}
					toastr.error(errfile.name + ' has not been uploaded. ' + errReason, 'Error');
				});
			}
			if (files && files.length > 0) {
				self.model.job.files = files;
				files.forEach(function (file, i) {
					file.icon = Utilities.assignFileIcon(file.name);
					self.formUI.image[i] = {
						progress: 0,
						style: 'width: 0%',
						cache: +new Date()
					};
				});

			}
		}

		function uploadFiles() {
			self.model.job.images = [];
			let promisesArr = [],
				deferred = $q.defer();
			self.model.job.files.forEach(function (file, i) {
				promisesArr.push(Upload.upload({
					url: APIConfigService.url('files'),
					data: {
						image: file,
						userId: self.model.job.userId,
					}
				}).then(function (resp) {
					self.model.job.images[i] = resp.data;
					toastr.success(resp.config.data.image.name + ' uploaded', 'Success');
					console.log(resp.data.filenames);
					return resp.data.filenames[0];
				}, function (resp) {
					toastr.error(resp.config.data.image.name + ' has not been uploaded', 'Error');
					return resp;
				}, function (evt) {
					self.formUI.image[i] = {
						progress: parseInt(100.0 * evt.loaded / evt.total),
						style: 'width: ' + parseInt(100.0 * evt.loaded / evt.total) + '%',
						cache: +new Date()
					};
					console.log('progress: ' + self.formUI.image[i].progress + '% ' + evt.config.data.image.name);
				}));
			});

			return $q.all(promisesArr);
		}

		function submitChanges() {
			// submit only allowed values
			let editedJob = {
				fee: parseFloat(self.model.job.fee),
				jobId: parseInt(self.model.job.jobId),
				jobactionId: parseInt(self.model.job.action.jobactionId),
				instructions: self.model.job.instructions,
				assigneeNotes: self.model.job.assigneeNotes,
				assigneeId: parseInt(self.model.job.assigneeId),
				filenamesUpload: self.model.job.filenamesUpload,
				filenamesFinal: self.model.job.filenamesFinal
			};
			let selfjobCopy = angular.copy(self.job);

			if (self.model.job.files && self.model.job.files.length > 0 &&
				(self.model.job.action.jobactionId === 4 || self.model.job.action.jobactionId === 23)) {
				uploadFiles().then(function (filenames) {
						editedJob.filenamesFinal = filenames;
						editedJob = Utilities.filterChanges(selfjobCopy, editedJob);
						editedJob.jobId = self.model.job.jobId;
						editedJob.jobactionId = self.model.job.action.jobactionId;
						editJob(editedJob);
						return true;
					},
					function (err) {
						console.log('error', err);
						return false;
					});
			} else {
				delete editedJob.filenamesUpload;
				delete editedJob.filenamesFinal;
				editedJob = Utilities.filterChanges(selfjobCopy, editedJob);
				if (_.isEmpty(editedJob) && self.model.job.action.showForm === true) {
					toastr.warning('No job data have been changed', 'Warning');
					return false;
				}
				editedJob.jobId = self.model.job.jobId;
				editedJob.jobactionId = self.model.job.action.jobactionId;
				editJob(editedJob);
			}

		}

		function editJob(jobData) {
			return JobService.editJob(jobData).then(function (response) {
				console.log('Job has been changed to ', jobData);
				if (undefined !== response && false === response.err) {
					toastr.success('Job data #' + response.id + ' have been changed', 'Success');
					init();
				} else {
					toastr.error('Job data have not been changed. Server says: ' + response.msg, 'Error');
					console.error(response.msg);
				}
			}).catch(function (error) {
				console.error(error);
			});
		}

		function discardChanges() {
			self.act = null;
			self.formUI.image = {
				progress: 0,
				style: 'width: 0%',
				cache: +new Date()
			};
			self.model.job = angular.copy(self.job);
			self.modelform.$pristine = true;
		}
		
	}
})();
