(function () {
	'use strict';
	angular.module('roi.app').factory('UserGroupService', UserGroupService);

	UserGroupService.$inject = ['$http', '$location', '$q', '$log', 'APIConfigService'];

	function UserGroupService($http, $location, $q, $log, APIConfigService) {

		let listPromise = undefined;
		return {
			getUserGroups: getUserGroups,
			getUserGroup: getUserGroup,
			addUserGroup: addUserGroup,
			editUserGroup: editUserGroup,
			deleteUserGroup: deleteUserGroup
		};
		
		function getUserGroups() {
			if(listPromise)
				return listPromise;
			let url = APIConfigService.url('usergroups');
			listPromise = $http.get(url)
			.then(onComplete)
			.catch(onFailed);

			return listPromise;
			function onComplete(response) {
				return response.data;
			}

			function onFailed(error) {
				console.log('XHR Failed for getGroups.' + error.data);
			}
		}
		
		function getUserGroup(groupId) {
			if(listPromise) {
				return listPromise.then(function(groups){
					let group = undefined;
					groups.forEach(function(groupItem){
						if(groupItem.ugroupId === groupId)
							group = groupItem;
					});
					return group;
				})
			}
			let url = APIConfigService.url('usergroups', groupId);
			return $http.get(url)
			.then(onComplete)
			.catch(onFailed);

			function onComplete(response) {
				return response.data;
			}

			function onFailed(error) {
				console.log('XHR Failed for getGroup.' + error.data);
			}
		}

		function addUserGroup(userGroupData) {
			let url = APIConfigService.url('usergroups');
			return $http.post(url, userGroupData)
			.then(function (response) {
				listPromise = undefined;
				return response.data;
			})
			.catch(onFailed);

			function onFailed(error) {
				console.log('XHR Failed for addUserGroup.' + error);
			}
		}

		function editUserGroup(userGroupData) {
			let url = APIConfigService.url('usergroups', userGroupData.ugroupId);
			return $http.put(url, userGroupData)
			.then(function (response) {
				listPromise = undefined; 
				return response.data;
			})
			.catch(onFailed);

			function onFailed(error) {
				console.log('XHR Failed for editUserGroup.' + error);
			}
		}
		
		function deleteUserGroup(ugroupId) {
			let url = APIConfigService.url('usergroups', ugroupId);
			return $http.delete(url)
			.then(function (response) {
				listPromise = undefined;
				return response.data;
			})
			.catch(onFailed);

			function onFailed(error) {
				console.log('XHR Failed for deleteUserGroup.' + error);
				if(error.data && error.data.err===true)
					throw error.data.msg;
			}
		}
	}
})();
