(function () {
	'use strict';
	angular.module('roi.transactiontypes')
	.config(['$stateProvider', function ($stateProvider) {
		$stateProvider.state('app.transactiontypes', {
			url: '/transaction-types',
			abstract: true,
			ncyBreadcrumb: {
				skip: true
			},
			template: '<ui-view></ui-view>'
		});
		$stateProvider.state('app.transactiontypes.list', {
			url: '/list',
			ncyBreadcrumb: {
				label: 'Transaction Types'
			},
			controller: 'TransactiontypesListController',
			controllerAs: 'ttc',
			templateUrl: './js/modules/transaction-types/transaction-type-list.html'
		});
		$stateProvider.state('app.transactiontypes.transactiontype', {
			url: '/transactiontype/:trId',
			ncyBreadcrumb: {
				parent: 'app.transactiontypes.list',
				label: 'Transaction Type'
			},
			controller: 'TransactiontypesController',
			controllerAs: 'ttc',
			templateUrl: './js/modules/transaction-types/transaction-type.html'
		});
		$stateProvider.state('app.transactiontypes.new', {
			url: '/transactiontype/new',
			ncyBreadcrumb: {
				parent: 'app.transactiontypes.list',
				label: 'Add New Transaction Type'
			},
			controller: 'NewTransactiontypeController',
			controllerAs: 'ttc',
			templateUrl: './js/modules/transaction-types/transaction-type-new.html'
		});
	}]);
})();