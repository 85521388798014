(function () {
	'use strict';
	angular.module('roi.app').factory('Utilities', Utilities);

	Utilities.$inject = [];

	function Utilities() {

		let regex = {
			email: /(?!^[.+&'_-]*@.*$)(^[_\w\d+&'-]+(\.[_\w\d+&'-]*)*@[\w\d-]+(\.[\w\d-]+)*\.(([\d]{1,3})|([\w]{2,}))$)/,
			decimal: /^\d*\.?\d{1,2}$/
		};

		return {
			regex: regex,
			filterChanges: filterChanges,
			arrayToObjectModel: arrayToObjectModel,
			objectToObjectModel: objectToObjectModel,
			getOnlyTrue: getOnlyTrue,
			someSelected: someSelected,
			isCheckboxSelected: isCheckboxSelected,
			modelFilter: modelFilter,
			getUuid: getUuid,
			getUnique: getUnique,
			getUserAvatarUrl: getUserAvatarUrl,
			assignFileIcon: assignFileIcon
		};

		function assignFileIcon(filename) {
			let extensions = {
					image: ['jpg', 'jpeg', 'gif', 'png'],
					icons: ['pdf', 'bmp', 'zip', 'doc', 'docx']
				},
				ext = filename.split('.').pop(),
				icon = null;
			if (extensions.image.indexOf(ext) === -1) {
				if (extensions.icons.indexOf(ext) !== -1) {
					switch (ext) {
						case 'pdf':
							icon = 'fa-file-pdf-o';
							break;
						case 'bmp':
							icon = 'fa-file-image-o';
							break;
						case 'zip':
							icon = 'fa-file-archive-o';
							break;
						case 'doc':
						case 'docx':
							icon = 'fa-file-word-o';
							break;
						default:
							icon = 'fa-file-image-o';
							break;
					}
				}
			}
			return icon;
		}
		function getUuid() {
			let rdate = new Date().getTime();
			return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g,
				function (c) {
					let r = (rdate + Math.random() * 16) % 16 | 0;
					return (c === 'x' ? r : (r & 0x7 | 0x8)).toString(16);
				});
		}
		function getUnique() {
			let rdate = new Date().getTime();
			return 'xxxxxxxx'.replace(/[xy]/g,
				function (c) {
					let r = (rdate + Math.random() * 16) % 16 | 0;
					return (c === 'x' ? r : (r & 0x7 | 0x8)).toString(16);
				});
		}
		function modelFilter(obj) {
			let model = _.pickBy(obj, Utilities.getOnlyTrue);
			if (_.isEmpty(model)) {
				return undefined;
			} else {
				return model;
			}
		}

		function isCheckboxSelected(arr, val) {
			return (_.indexOf(arr, val) !== -1);
		}

		function someSelected(obj) {
			if (obj !== undefined && obj !== null) {
				return Object.keys(obj).some(function (key) {
					return obj[key];
				});
			}
		}

		function getOnlyTrue(val) {
			return (Boolean(val) === true);
		}

		function arrayToObjectModel(arr) {
			let obj = {};
			if (_.isArray(arr)) {
				arr.forEach(function (val) {
					obj[val] = true;
				});
				return obj;
			}
			return obj;
		}

		function objectToObjectModel(object) {
			let obj = {},
				key,
				value;
			object.forEach(function (val) {
				if (Object.keys(val).length > 0) {
					key = Object.keys(val)[0];
				}
				if (Object.values(val).length > 0) {
					value = Object.values(val)[0];
				}
				obj[key] = arrayToObjectModel(value);
			});
			return obj;
		}

		function filterChanges(obj1, obj2) {
			let changedValues = {};

			for (let p in obj1) {
				if (obj1.hasOwnProperty(p) !== obj2.hasOwnProperty(p)) {
					continue;
				}
				switch (typeof (obj1[p])) {
					case 'object':
						if(obj1[p] === null && obj2[p] !== null) {
							changedValues[p] = obj2[p];
							break;
						}
						if (_.isArray(obj1[p]) && !_.isEqual(obj1[p].sort(), obj2[p].sort())) {
							changedValues[p] = obj2[p];
							break;
						}
						let obj3 = filterChanges(obj1[p], obj2[p]);
						if (!_.isEmpty(obj3)) {
							changedValues[p] = obj3;
						}
						break;
					case 'function':
						break;
					default:
						if (obj1[p] !== obj2[p]) {
							changedValues[p] = obj2[p];
						}
				}
			}
			return changedValues;
		}
		
		function getUserAvatarUrl(user) {
			if(user.avatar) {
				return `/uploads/${user.userId}/${user.avatar}`;
			}
			return '';
		}
	}
})();
