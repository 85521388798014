(function () {
	'use strict';
	angular.module('roi.transactiontypes').controller('TransactiontypesListController', TransactiontypesListController);

	TransactiontypesListController.$inject = ['$scope', '$state', '$uibModal', 'TransactiontypeService', 'StaticDataService', 'toastr'];

	function TransactiontypesListController($scope, $state, $uibModal, TransactiontypeService, StaticDataService, toastr) {
		let self = this;

		self.transactiontypes = [];
		self.transactiontypeTable = [];
		self.selectedTransactiontype = null;

		self.editTransactiontype = editTransactiontype;
		self.deleteTransactiontype = deleteTransactiontype;
		self.confirm = confirm;
		self.cancel = cancel;

		init();

		function init() {
			TransactiontypeService.getTransactiontypes().then(function(transactiontypes){
				self.transactiontypes = transactiontypes;
				self.transactiontypeTable = angular.copy(transactiontypes);
			})
		}

		function editTransactiontype(trId) {
			$state.go('app.transactiontypes.transactiontype', { trId: trId });
		}

		function deleteTransactiontype(transactiontype) {
			self.selectedTransactiontype = transactiontype;
			self.modalInstance = $uibModal.open({
				animation: true,
				scope: $scope,
				ariaLabelledBy: 'modal-title',
				ariaDescribedBy: 'modal-body',
				templateUrl: 'deletetransactiontype.html',
				size: 'sm',
				resolve: {}
			});
			self.modalInstance.result.then(function (response) {
				return TransactiontypeService.deleteTransactiontypes(self.selectedTransactiontypes.transactiontypeId).then(function (response) {
					if (response.err === false) {
						toastr.success('Transaction type has been deleted', 'Success');
						removeItem(transactiontype);
					} else {
						toastr.error('Transaction type has not been deleted', 'Error');
					}
					self.selectedTransactiontype = null;
				}).catch(function (error) {
					self.selectedTransactiontype = null;
					console.error(error);
				});
			}, function () {
				self.selectedTransactiontype = null;
			});
		}

		function confirm() {
			self.modalInstance.close();
		}

		function cancel() {
			self.modalInstance.dismiss();
		}

		// table functions
		function removeItem(row) {
			self.transactiontypes = _.filter(self.transactiontypes, function (o) {
				return o.transactiontypeId !== row.transactiontypeId;
			});
		}

	}
})();