(function () {
	'use strict';
	angular.module('roi.jobtypes')
	.config(['$stateProvider', function ($stateProvider) {
		$stateProvider.state('app.jobtypes', {
			url: '/jobtypes',
			abstract: true,
			ncyBreadcrumb: {
				skip: true
			},
			template: '<ui-view></ui-view>'
		});
		$stateProvider.state('app.jobtypes.list', {
			url: '/list',
			ncyBreadcrumb: {
				label: 'Job Types'
			},
			controller: 'JobTypeListController',
			controllerAs: 'tc',
			templateUrl: './js/modules/jobtypes/jobtype-list.html'
		});
		$stateProvider.state('app.jobtypes.jobtype', {
			url: '/jobtype/:jobtypeId',
			ncyBreadcrumb: {
				parent: 'app.jobtypes.list',
				label: 'Job Type'
			},
			controller: 'JobTypeController',
			controllerAs: 'tc',
			templateUrl: './js/modules/jobtypes/jobtype.html'
		});
		$stateProvider.state('app.jobtypes.workflow', {
			url: '/workflow/:jobtypeId',
			ncyBreadcrumb: {
				parent: 'app.jobtypes.list',
				label: 'Workflow'
			},
			controller: 'WorkFlowController',
			controllerAs: 'tc',
			templateUrl: './js/modules/jobtypes/workflow.html'
		});
		$stateProvider.state('app.jobtypes.new', {
			url: '/jobtype/new',
			ncyBreadcrumb: {
				parent: 'app.jobtypes.list',
				label: 'Add New Job Type'
			},
			controller: 'NewJobTypeController',
			controllerAs: 'tc',
			templateUrl: './js/modules/jobtypes/jobtype-new.html'
		});
	}]);
})();