(function () {
	'use strict';
	angular.module('roi.users').controller('UserController', UserController);

	UserController.$inject = ['$scope', '$state', '$stateParams', '$q', 'Upload', 'toastr',
		'UserService', 'StaticDataService', 'APIConfigService', 'Utilities'];

	function UserController($scope, $state, $stateParams, $q, Upload, toastr,
	                        UserService, StaticDataService, APIConfigService, Utilities) {
		let self = this;
		self.user = null;   //original data

		self.model = {
			user: null
		};

		self.formUI = {};
		self.formUI.regex = Utilities.regex;
		self.formUI.zipmask = undefined;
		self.formUI.avatarProgress = {
			progress: 0,
			style: 'width: 0%',
			cache: +new Date()
		};
		self.addFiles = addFiles;
		self.getCountryRegions = getCountryRegions;
		self.uploadFiles = uploadFiles;
		self.discardChanges = discardChanges;
		self.submitChanges = submitChanges;

		init();

		function init() {
			UserService.getUser($stateParams.userId).then(function (user) {
				user.password = null;
				self.user = user;
				self.model.user = angular.copy(user);
				self.model.user.avatarFile = Utilities.getUserAvatarUrl(user);
				self.user.avatarFile = self.model.user.avatarFile;
				StaticDataService.getStaticData().then(function (listData) {
					self.formUI.groups = listData.groups;
					self.formUI.currencies = listData.currencies;
					self.formUI.countries = listData.countries;
					self.formUI.taxes = listData.taxes;
					assignDataToUser();
				});

			}).catch(function (error) {
				console.error(error);
			});
			getArtists();
		}

		function submitChanges() {
			let userData = angular.copy(self.model.user);
			if (userData.address && userData.address.country) {
				userData.address.countryId = userData.address.country.countryId;
				userData.address.countryName = userData.address.country.name;
				delete userData.address.country;
			}
			userData.jobFee = parseFloat(userData.jobFee);
			userData.creditLimit = parseFloat(userData.creditLimit);
			userData.groups = [userData.ugroupId];
			delete userData.ugroupId;
			userData.assignees = [];
			if (userData.defaultArtistId) {
				userData.assignees.push({
					jobtypeId: 1,
					userId: userData.defaultArtistId
				});
				delete userData.defaultArtistId;
			}
			if (userData.defaultCustomArtistId) {
				userData.assignees.push({
					jobtypeId: 2,
					userId: userData.defaultCustomArtistId
				});
				delete userData.defaultCustomArtistId;
			}
			userData = Utilities.filterChanges(self.user, userData);
			if (_.isEmpty(userData)) {
				toastr.warning('No user data have been changed', 'Warning');
				return false;
			}
			userData.userId = self.model.user.userId;
			let avatarFile = typeof self.model.user.avatarFile === 'object' ? self.model.user.avatarFile : undefined;
			uploadFiles(avatarFile).then(function(avatar){
				if(avatar)
					userData.avatar = avatar;
				editUser(userData);
			})
		}
		
		function addFiles(files, errFiles) {
			if(errFiles && errFiles.length > 0) {
				errFiles.forEach( function(errfile) {
					let errReason = 'Wrong file format.';
					switch(errfile.$error) {
						case 'maxFiles':
							errReason = 'Too many files, only ' + errfile.$errorParam + ' are allowed.';
							break;
					}
					toastr.error( errfile.name + ' has not been uploaded. ' + errReason, 'Error' );
				} );
			}
		}

		function discardChanges() {
			self.model.user = angular.copy(self.user);
			assignDataToUser();
		}

		function assignDataToUser() {
			if (self.model.user.address) {
				self.model.user.address.country = _.find(self.formUI.countries, { countryId: self.model.user.address.countryId });
				getCountryRegions();
			}
			if (self.model.user.groups && self.model.user.groups.length > 0) {
				self.model.user.ugroupId = self.model.user.groups[0];
			}
			if (self.model.user.assignees && self.model.user.assignees.length > 0) {
				self.model.user.assignees.forEach(function (assignee) {
					if (assignee.jobtypeId === 1) {
						self.model.user.defaultArtistId = assignee.userId;
					} else if (assignee.jobtypeId === 2) {
						self.model.user.defaultCustomArtistId = assignee.userId;
					}
				});
			}
		}

		function getCountryRegions() {
			let country = self.model.user.address.country;
			if (country) {
				self.formUI.regions = (country.regions && country.regions.length > 0) ? country.regions : null;
				self.formUI.zipmask = undefined;
				if (country.postCodeMask !== '') {
					self.formUI.zipmask = country.postCodeMask;
				}
			}
		}

		function uploadFiles(file) {
			if (file) {
				self.formUI.avatarProgress = {
					progress: 0,
					style: 'width: 0%',
					cache: +new Date()
				};
				self.model.user.avatar = null;
				let url = APIConfigService.url('files');
				return Upload.upload({
					url: url,
					data: {
						image: file
					}
				}).then(function (resp) {
					if(resp.data.filenames && resp.data.filenames.length > 0) {
						self.model.user.avatar = resp.data.filenames[0];
						return resp.data.filenames[0];
					}
					return false;
				}, function (resp) {
					toastr.error(resp.data.image.name + ' has not been uploaded', 'Error');
					console.log('Error status: ' + resp);
				}, function (evt) {
					self.formUI.avatarProgress = {
						progress: parseInt(100.0 * evt.loaded / evt.total),
						style: 'width: ' + parseInt(100.0 * evt.loaded / evt.total) + '%',
						cache: +new Date()
					};
				});
			} else {
				return $q.resolve(false);
			}
		}

		function getArtists() {
			return UserService.getUsers({ ugroupId: 2 }).then(function (artists) {
				self.formUI.artists = artists;
				return artists;
			}).catch(function (error) {
				console.error(error);
			});
		}

		function editUser(userData) {
			return UserService.editUser(userData).then(function (response) {
				if (response.err === false) {
					toastr.success('User data have been changed', 'Success');
					$state.go('app.users.list');
				} else {
					toastr.error('User data have not been changed', 'Error');
				}
			}).catch(function (error) {
				console.error(error);
			});
		}
	}
})();
