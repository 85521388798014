(function () {
	'use strict';
	angular.module('roi.app').factory('StatisticsService', StatusService);

	StatusService.$inject = ['$rootScope', '$http', '$location', '$q', '$log', 'APIConfigService'];

	function StatusService($rootScope, $http, $location, $q, $log, APIConfigService) {
		let statsPromise = undefined;
		let top5ByJobsPromise = undefined;
		let top5ByPayPromise = undefined;
		let lastActiveUsersPromise = undefined;
		let lastAddedJobsPromise = undefined;
		/*
${app}/index.php?XDEBUG_SESSION_START=VW$&scat=stats
+- Сколько всего посетителей зарегистрировано на сайте
+- Сколько работ (в любых статусах) на данный момент на сайте
+- Сколько денег заработано за всё время на сайте
+- Среднее время выполнения заказа (etching job) на сайте

- Статистика с графиками:
+а) Сколько работ в данный момент на сайте (можно выбрать статус работы - по умолчанию - все Not Completed, и период) - pie chart, up to now
${app}/index.php?XDEBUG_SESSION_START=VW$&scat=stats&id=orders&ssub=bystatus&from=2017-01-01&to=2018-12-31

+- Top 5 пользователей за период (по умолчанию - месяц) - по колличеству заказанных работ, по колличеству пополнения счёта.
${app}/index.php?XDEBUG_SESSION_START=VW$&scat=stats&id=users&ssub=topNbyjob&from=2017-01-01&to=2018-12-31
${app}/index.php?XDEBUG_SESSION_START=VW$&scat=stats&id=users&ssub=topNbypay&from=2017-01-01&to=2018-12-31
- Последние 5 пользователей, которые заходили на сайт
${app}/index.php?XDEBUG_SESSION_START=VW$&scat=stats&id=users&ssub=lastNvisit
- Последние 5 добавленных работ с их статусом (из etching jobs and custom jobs)
${app}/index.php?XDEBUG_SESSION_START=VW$&scat=stats&id=orders&ssub=lastN&N=5
- количество работ по дням/неделям/месяцам, в зависимости от размера диапазона. По умолчанию - месяц. Не больше 32 данных в массиве
${app}/index.php?XDEBUG_SESSION_START=VW$&scat=stats&id=orders&ssub=byrange&from=2018-01-15&to=2018-09-04
- размер доходов по дням/неделям/месяцам, в зависимости от размера диапазона. По умолчанию - месяц. Не больше 32 данных в массиве
${app}/index.php?XDEBUG_SESSION_START=VW$&scat=stats&id=revenue&ssub=byrange&from=2018-01-15&to=2018-09-04
*/
		$rootScope.$on( 'userlogout', function() {
			statsPromise = undefined;
			top5ByJobsPromise = undefined;
			top5ByPayPromise = undefined;
			lastActiveUsersPromise = undefined;
			lastAddedJobsPromise = undefined;
		} );
		
		return {
			getStats: getStats,
			getLastActiveUsers: getLastActiveUsers,
			getLastAddedJobs: getLastAddedJobs,
			getTop5ByJobs: getTop5ByJobs,
			getTop5ByPay: getTop5ByPay,
			getJobsByRange: getJobsByRange,
			getRevenueByRange: getRevenueByRange
		};

		function getLastActiveUsers() {
			if (lastActiveUsersPromise) {
				return lastActiveUsersPromise;
			}
			let url = APIConfigService.url('stats', 'users', 'lastNvisit');
			lastActiveUsersPromise = $http.get(url)
			.then(onComplete)
			.catch(onFailed);
			return lastActiveUsersPromise;

			function onComplete(response) {
				return response.data;
			}

			function onFailed(error) {
				console.log('XHR Failed for getLastActiveUsers.' + error);
			}

		}

		function getLastAddedJobs(num) {
			if (lastAddedJobsPromise) {
				return lastAddedJobsPromise;
			}
			let obj = { N: num };
			let url = APIConfigService.url('stats', 'orders', 'lastN', obj);
			lastAddedJobsPromise = $http.get(url)
			.then(onComplete)
			.catch(onFailed);
			return lastAddedJobsPromise;

			function onComplete(response) {
				return response.data;
			}

			function onFailed(error) {
				console.log('XHR Failed for getLastAddedJobs.' + error);
			}
		}

		function getStats() {
			if (statsPromise) {
				return statsPromise;
			}
			let url = APIConfigService.url('stats');
			statsPromise = $http.get(url)
			.then(onComplete)
			.catch(onFailed);

			return statsPromise;

			function onComplete(response) {
				return response.data;
			}

			function onFailed(error) {
				console.log('XHR Failed for getStats.' + error);
			}
		}

		function getTop5ByJobs(type) {
			if (top5ByJobsPromise) {
				return top5ByJobsPromise;
			}
			let url = APIConfigService.url('stats', 'users', 'topNbyjob');
			top5ByJobsPromise = $http.get(url)
			.then(onComplete)
			.catch(onFailed);

			return top5ByJobsPromise;

			function onComplete(response) {
				return response.data;
			}

			function onFailed(error) {
				console.log('XHR Failed for getTop5ByJobs.' + error);
			}
		}

		function getTop5ByPay(type) {
			if (top5ByPayPromise) {
				return top5ByPayPromise;
			}
			let url = APIConfigService.url('stats', 'users', 'topNbypay');
			top5ByPayPromise = $http.get(url)
			.then(onComplete)
			.catch(onFailed);

			return top5ByPayPromise;

			function onComplete(response) {
				return response.data;
			}

			function onFailed(error) {
				console.log('XHR Failed for getTop5ByPay.' + error);
			}
		}

		function getJobsByRange(from, to) {
			let params = {};
			if (from && to) {
				params.from = from;
				params.to = to;
			}
			let url = APIConfigService.url('stats', 'orders', 'byrange', params);
			return $http.get(url)
			.then(onComplete)
			.catch(onFailed);

			function onComplete(response) {
				let data = response.data,
					jobsbyrange = data.jobsbyrange1,
					ret = { dates: [], quantity: [], quantity1: [], quantity2: [], total1: 0, total2: 0, total: 0 },
					ranges = [];

				if (0 === Object.getOwnPropertyNames(jobsbyrange).length) {
					return ret;
				}

				for (let x in jobsbyrange) {
					if (!jobsbyrange.hasOwnProperty(x)) {
						continue;
					}
					ranges.push({ d: x, q: jobsbyrange[x] });
				}
				ranges.sort(function (a, b) {
					return a.d.localeCompare(b.d);
				});
				for (let i = 0; i < ranges.length; i++) {
					ret.dates.push(ranges[i].d);
					ret.quantity1.push(ranges[i].q);
				}
				//range2
				jobsbyrange = data.jobsbyrange2;
				ranges = [];
				for (let x in jobsbyrange) {
					if (!jobsbyrange.hasOwnProperty(x)) {
						continue;
					}
					ranges.push({ d: x, q: jobsbyrange[x] });
				}
				ranges.sort(function (a, b) {
					return a.d.localeCompare(b.d);
				});
				for (let i = 0; i < ranges.length; i++) {
					ret.quantity2.push(ranges[i].q);
					ret.quantity.push(ret.quantity2[i] + ret.quantity1[i]);
				}

				// total
				ret.total1 = _.sum(ret.quantity1);
				ret.total2 = _.sum(ret.quantity2);
				ret.total = ret.total1 + ret.total2;

				return ret;
			}

			function onFailed(error) {
				console.log('XHR Failed for getJobsByRange.' + error);
			}
		}

		function getRevenueByRange(from, to) {
			let params = {};
			if (from && to) {
				params.from = from;
				params.to = to;
			}
			let url = APIConfigService.url('stats', 'revenue', 'byrange', params);
			return $http.get(url)
			.then(onComplete)
			.catch(onFailed);

			function onComplete(response) {
				let data = response.data,
					revbyrange = data.revenuebyrange1,
					ret = { dates: [], quantity1: [], quantity2: [], quantity3: [] },
					ranges = [];

				if (0 === Object.getOwnPropertyNames(revbyrange).length) {
					return ret;
				}

				for (let x in revbyrange) {
					if (!revbyrange.hasOwnProperty(x)) {
						continue;
					}
					ret.dates.push(x);
				}
				ret.dates.sort(function (a, b) {
					return a.localeCompare(b);
				});
				ret.quantity1 = processQuantity(data.revenuebyrange1, ret.dates);
				ret.quantity2 = processQuantity(data.revenuebyrange2, ret.dates);
				ret.quantity3 = processQuantity(data.revenuebyrange3, ret.dates);

				return ret;
			}

			function processQuantity(revbyrange, dates) {
				let quantity = [];
				for (let i = 0; i < dates.length; i++) {
					quantity.push(revbyrange[dates[i]]);
				}
				return quantity;
			}

			function onFailed(error) {
				console.log('XHR Failed for getJobsByRange.' + error);
			}
		}

	}
})();
