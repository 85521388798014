(function () {
	'use strict';
	angular.module('roi.jobs')
	.config(['$stateProvider', function ($stateProvider) {
		$stateProvider.state('app.jobs', {
			url: '/jobs',
			abstract: true,
			ncyBreadcrumb: {
				skip: true
			},
			template: '<ui-view></ui-view>'
		})
		.state('app.jobs.list', {
			url: '/list',
			ncyBreadcrumb: {
				label: 'Job List'
			},
			controller: 'JobListController',
			controllerAs: 'jc',
			templateUrl: './js/modules/jobs/job-list.html'
		})
		.state('app.jobs.job', {
			url: '/job/:jobId',
			ncyBreadcrumb: {
				parent: 'app.jobs.list',
				label: 'Job'
			},
			controller: 'JobController',
			controllerAs: 'jc',
			templateUrl: './js/modules/jobs/job.html'
		})
		.state('app.jobs.new', {
			url: '/job/new/:jobtypeId',
			ncyBreadcrumb: {
				parent: 'app.jobs.list',
				label: 'Add New Job'
			},
			controller: 'NewJobController',
			controllerAs: 'jc',
			templateUrl: './js/modules/jobs/job-new.html'
		})
		.state('app.jobs.newcatalog', {
			url: '/job/newcatalog',
			ncyBreadcrumb: {
				parent: 'app.jobs.list',
				label: 'New Catalog Request'
			},
			controller: 'NewJobCatalogController',
			controllerAs: 'jc',
			templateUrl: './js/modules/jobs/job-new-catalog.html'
		});
	}]);
})();
