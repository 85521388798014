(function () {
	'use strict';
	angular.module('roi.app').factory('StatusService', StatusService);

	StatusService.$inject = ['$http', '$location', '$q', '$log', 'APIConfigService'];

	function StatusService($http, $location, $q, $log, APIConfigService) {

		return {
			getStatuses: getStatuses,
			addStatus: addStatus,
			editStatus: editStatus,
			deleteStatus: deleteStatus
		};

		function getStatuses() {
			let url = APIConfigService.url('jobstatus');
			return $http.get(url)
			.then(onComplete)
			.catch(onFailed);

			function onComplete(response) {
				return response.data;
			}

			function onFailed(error) {
				console.log('XHR Failed for getStatuses.' + error);
			}
		}
		
		function addStatus(statusData) {
			let url = APIConfigService.url('jobstatus');
			return $http.post(url, statusData)
			.then(function (response) {
				return response.data;
			})
			.catch(onFailed);

			function onFailed(error) {
				console.log('XHR Failed for addStatus.' + error.data);
			}
		}

		function editStatus(statusData) {
			let url = APIConfigService.url('jobstatus', statusData.jobstatusId);
			return $http.put(url, statusData)
			.then(function (response) {
				return response.data;
			})
			.catch(onFailed);

			function onFailed(error) {
				console.log('XHR Failed for editStatus.' + error.data);
			}
		}

		function deleteStatus(jobstatusId) {
			let url = APIConfigService.url('jobstatus', jobstatusId);
			return $http.delete(url)
			.then(function (response) {
				return response.data;
			})
			.catch(onFailed);

			function onFailed(error) {
				console.log('XHR Failed for deleteStatus.' + error.data);
			}
		}
	}
})();