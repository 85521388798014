(function() {
	'use strict';
	angular.module( 'roi.app' ).controller( 'AppController', AppController );

	AppController.$inject = ['$scope', 'curUser'];

	function AppController($scope, curUser) {
		let self = this;
		$scope.curUser = curUser; // needs to be in the scope to be accessible from this scope down.

		init();

		function init() {
		}
	}
})();
