(function () {
	'use strict';
	angular.module('roi.instructions').controller('InstructionController', InstructionController);

	InstructionController.$inject = ['$scope', '$state', '$stateParams', 'InstructionService', 'StaticDataService', 'Utilities', 'toastr'];

	function InstructionController($scope, $state, $stateParams, InstructionService, StaticDataService, Utilities, toastr) {
		let self = this;
		self.instruction = null;

		self.modelform = {};
		self.model = {
			instruction: null
		};

		self.discardChanges = discardChanges;
		self.submitChanges = submitChanges;

		init();

		function init() {
			InstructionService.getInstruction(parseInt($stateParams.id)).then(function(instruction){
				self.instruction = instruction;
				self.model.instruction = angular.copy(instruction);
			})
		}

		function submitChanges() {
			let submittedData = angular.copy(self.model.instruction),
				instructionData = Utilities.filterChanges(self.instruction, submittedData);
			if (_.isEmpty(instructionData)) {
				toastr.warning('No instruction data have been changed', 'Warning');
				return false;
			}
			instructionData.configId = self.model.instruction.configId;
			editInstruction(instructionData);
		}

		function discardChanges() {
			self.model.instruction = angular.copy(self.instruction);
		}

		function editInstruction(instructionData) {
			return InstructionService.editInstruction(instructionData).then(function (response) {
				console.log('Instruction has been changed to ', instructionData);
				if (response.err === false) {
					toastr.success('Instruction data have been changed', 'Success');
					$state.go('app.instructions.list');
				} else {
					toastr.error('Instruction data have not been changed', 'Error');
				}
			}).catch(function (error) {
				console.error(error);
			});
		}
	}
})();
