(function () {
	'use strict';
	angular.module('roi.users').controller('NewUserController', NewUserController);

	NewUserController.$inject = ['$scope', '$state', '$q', '$stateParams', 'UserService', 'StaticDataService', 'APIConfigService', 'Utilities', 'Upload', 'toastr'];

	function NewUserController($scope, $state, $q, $stateParams, UserService, StaticDataService, APIConfigService, Utilities, Upload, toastr) {
		let self = this;
		self.user = null;

		self.model = {
			user: null
		};

		self.formUI = {};
		self.formUI.regex = Utilities.regex;
		self.formUI.zipmask = undefined;
		self.formUI.avatarProgress = {
			progress: 0,
			style: 'width: 0%',
			cache: +new Date()
		};

		self.getCountryRegions = getCountryRegions;
		self.addFiles = addFiles;
		self.submitData = submitData;

		init();

		function init() {
			getArtists();
			StaticDataService.getStaticData().then(function(listData){
				self.formUI.groups = listData.groups;
				self.formUI.currencies = listData.currencies;
				self.formUI.countries  = listData.countries;
				self.formUI.taxes = listData.taxes;
			})
		}

		function submitData() {
			let userData = angular.copy(self.model.user);
			if(userData.address && userData.address.country) {
				userData.address.countryId = userData.address.country.countryId;
				delete userData.address.country;
			}
			userData.jobFee = parseFloat(userData.jobFee);
			userData.creditLimit = parseFloat(userData.creditLimit);
			userData.groups=[userData.ugroupId];
			delete userData.ugroupId;
			userData.assignees=[];
			if(userData.defaultArtistId) {
				userData.assignees.push({
					jobtypeId:1,
					userId: userData.defaultArtistId
				});
				delete userData.defaultArtistId;
			}
			if(userData.defaultCustomArtistId) {
				userData.assignees.push({
					jobtypeId:2,
					userId: userData.defaultCustomArtistId
				});
				delete userData.defaultCustomArtistId;
			}
			if (_.isEmpty(userData)) {
				toastr.warning('You did not fill user data', 'Warning');
				return false;
			}
			let avatarFile = typeof self.model.user.avatarFile === 'object' ? self.model.user.avatarFile : undefined;
			uploadFiles(avatarFile).then(function(avatar){
				if(avatar)
					userData.avatar = avatar;
				addUser(userData);
			})
		}

		function getCountryRegions() {
			let country = self.model.user.address.country;
			self.formUI.regions = (country.regions && country.regions.length > 0) ? country.regions : null;
			self.formUI.zipmask = undefined;
			if(country.postCodeMask !== "") {
				self.formUI.zipmask = country.postCodeMask;
			}
		}

		function addFiles(files, errFiles) {
			if (errFiles) {
				errFiles.forEach(function (errfile) {
					let errReason = 'Wrong file format.';
					switch (errfile.$error) {
						case 'maxFiles':
							errReason = 'Too many files, only ' + errfile.$errorParam + ' are allowed.';
							break;
					}
					toastr.error(errfile.name + ' has not been uploaded. ' + errReason, 'Error');
				});
			}
		}

		function uploadFiles(file) {
			if (file) {
				self.formUI.avatarProgress = {
					progress: 0,
					style: 'width: 0%',
					cache: +new Date()
				};
				self.model.user.avatar = null;
				let url = APIConfigService.url('files');
				return Upload.upload({
					url: url,
					data: {
						image: file
					}
				}).then(function (resp) {
					if(resp.data.filenames && resp.data.filenames.length > 0) {
						self.model.user.avatar = resp.data.filenames[0];
						return resp.data.filenames[0];
					}
					return false;
				}, function (resp) {
					toastr.error(resp.data.msg, 'Error');
					console.log('Error status: ' + resp);
				}, function (evt) {
					self.formUI.avatarProgress = {
						progress: parseInt(100.0 * evt.loaded / evt.total),
						style: 'width: ' + parseInt(100.0 * evt.loaded / evt.total) + '%',
						cache: +new Date()
					};
				});
			} else {
				return $q.resolve(false);
			}
		}

		function getArtists() {
			return UserService.getUsers({ ugroupId: 2 }).then(function (artists) {
				self.formUI.artists = angular.copy(artists);
				return artists;
			}).catch(function (error) {
				console.error(error);
			});
		}

		function addUser(userData) {
			return UserService.addUser( userData ).then( function(response) {
				if(response.err === false) {
					toastr.success( 'User data have been added', 'Success' );
					$state.go( 'app.users.list' );
				} else {
					toastr.error( 'User data have not been changed', 'Error' );
				}
			} ).catch( function(error) {
				console.error( error );
			} );
		}
	}
})();
