var brandPrimary = '#2E86AB';
var brandSuccess = '#BFAB25';
var brandInfo = '#5DB8E1';
var brandWarning = '#FFC857';
var brandDanger = '#F25F5C';

var grayDark = '#1F232D';
var gray = '#5C6170';
var grayLight = '#808490';
var grayLighter = '#C8CACF';
var grayLightest = '#f0f3f5';

(function () {// Default colors
	'use strict';
	angular.module('roi.layout.header', []);
	angular.module('roi.dashboard', []);
	angular.module('roi.users', []);
	angular.module('roi.usergroups', []);
	angular.module('roi.taxes', []);
	angular.module('roi.statuses', []);
	angular.module('roi.actions', []);
	angular.module('roi.jobtypes', []);
	angular.module('roi.transactiontypes', []);
	angular.module('roi.transactions', []);
	angular.module('roi.instructions', []);
	angular.module('roi.notifications', []);
	angular.module('roi.jobs', []);

	angular.module('roi.app', [
		'ngAnimate',
		'ngSanitize',
		'angular.filter',
		'ngLetterAvatar',
		'chart.js',
		'ui.router',
		'ui.bootstrap',
		'ui.bootstrap.datetimepicker',
		'toastr',
		'oc.lazyLoad',
		'ncy-angular-breadcrumb',
		'angular-loading-bar',
		'smart-table',
		'ui.mask',
		'angularMoment',
		'ngFileUpload',
		'roi.layout.header',
		'roi.dashboard',
		'roi.users',
		'roi.usergroups',
		'roi.taxes',
		'roi.statuses',
		'roi.actions',
		'roi.jobtypes',
		'roi.instructions',
		'roi.notifications',
		'roi.jobs',
		'roi.transactions',
		'roi.transactiontypes',
	])
	.config(['toastrConfig', 'cfpLoadingBarProvider', function (toastrConfig, cfpLoadingBarProvider) {
		cfpLoadingBarProvider.includeSpinner = false;
		cfpLoadingBarProvider.latencyThreshold = 1;
		angular.extend(toastrConfig, {
			autoDismiss: false
		});
	}])
	.config(['$httpProvider', function($httpProvider){
		$httpProvider.interceptors.push('authHttpInterceptor');
	}])
	.run(['$rootScope', '$state', '$stateParams', '$transitions', function ($rootScope, $state, $stateParams, $transitions) {
		
	}]);
})();
