(function () {
	'use strict';
	angular
	.module('roi.app')
	.config(['$stateProvider', '$urlRouterProvider', '$ocLazyLoadProvider', '$breadcrumbProvider', function ($stateProvider, $urlRouterProvider, $ocLazyLoadProvider, $breadcrumbProvider) {
		$stateProvider
		.state('app.icons', {
			url: "/icons",
			abstract: true,
			template: '<ui-view></ui-view>',
			ncyBreadcrumb: {
				label: 'Icons'
			}
		})
		.state('app.icons.fontawesome', {
			url: '/font-awesome',
			templateUrl: 'views/icons/font-awesome.html',
			ncyBreadcrumb: {
				label: 'Font Awesome'
			}
		})
		.state('app.icons.simplelineicons', {
			url: '/simple-line-icons',
			templateUrl: 'views/icons/simple-line-icons.html',
			ncyBreadcrumb: {
				label: 'Simple Line Icons'
			}
		})
		.state('app.components', {
			url: "/components",
			abstract: true,
			template: '<ui-view></ui-view>',
			ncyBreadcrumb: {
				label: 'Components'
			}
		})
		.state('app.components.buttons', {
			url: '/buttons',
			templateUrl: 'views/components/buttons.html',
			ncyBreadcrumb: {
				label: 'Buttons'
			}
		})
		.state('app.components.social-buttons', {
			url: '/social-buttons',
			templateUrl: 'views/components/social-buttons.html',
			ncyBreadcrumb: {
				label: 'Social Buttons'
			}
		})
		.state('app.components.cards', {
			url: '/cards',
			templateUrl: 'views/components/cards.html',
			ncyBreadcrumb: {
				label: 'Cards'
			}
		})
		.state('app.components.forms', {
			url: '/forms',
			templateUrl: 'views/components/forms.html',
			ncyBreadcrumb: {
				label: 'Forms'
			}
		})
		.state('app.components.switches', {
			url: '/switches',
			templateUrl: 'views/components/switches.html',
			ncyBreadcrumb: {
				label: 'Switches'
			}
		})
		.state('app.components.tables', {
			url: '/tables',
			templateUrl: 'views/components/tables.html',
			ncyBreadcrumb: {
				label: 'Tables'
			}
		})
		.state('app.widgets', {
			url: '/widgets',
			templateUrl: 'views/widgets.html',
			ncyBreadcrumb: {
				label: 'Widgets'
			},
			resolve: {

			}
		})
		.state('app.charts', {
			url: '/charts',
			templateUrl: 'views/charts.html',
			ncyBreadcrumb: {
				label: 'Charts'
			},
			resolve: {

			}
		})
	}]);
})();