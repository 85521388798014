(function () {
	'use strict';
	angular.module('roi.transactions')
	.config(['toastrConfig','$stateProvider', function (toastrConfig, $stateProvider) {
		angular.extend(toastrConfig, {
			autoDismiss: false
		});
		$stateProvider.state('app.transactions', {
			url: '/transactions',
			abstract: true,
			ncyBreadcrumb: {
				skip: true
			},
			template: '<ui-view></ui-view>'
		});
		$stateProvider.state('app.transactions.list', {
			url: '/list',
			ncyBreadcrumb: {
				label: 'Transaction List'
			},
			controller: 'TransactionListController',
			controllerAs: 'tc',
			templateUrl: './js/modules/transactions/transaction-list.html'
		});
		$stateProvider.state('app.transactions.invoice', {
			url: '/invoice/:transactionId',
			ncyBreadcrumb: {
				parent: 'app.transactions.list',
				label: 'Invoice'
			},
			controller: 'InvoiceController',
			controllerAs: 'ic',
			templateUrl: './js/modules/transactions/invoice.html'
		});
	}]);
})();