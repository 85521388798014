(function () {
	'use strict';
	angular.module('roi.layout.header').directive('headerComponent', headerComponent);

	function headerComponent() {
		return {
			restrict: 'E',
			templateUrl: 'js/components/header/header.html',
			controller: headerComponentController,
			controllerAs: 'headerCtrl',
			bindToController: true
		};
	}

	headerComponentController.$inject = ['$scope', '$state', 'SessionService', 'NotificationService'];

	function headerComponentController($scope, $state, SessionService, NotificationService) {
		let self = this;
		self.curUser = $scope.curUser;
		self.userNotifications = [];
		self.logout = logout;

		function logout() {
			SessionService.logout().then(function () {
				$state.go('applogin');
			});
		}
		NotificationService.getUserNotifications().then(function(notifications){
			notifications.forEach(function(notification){
				self.userNotifications.push(notification);
			})
		});
	}
})();
