(function () {
	'use strict';
	angular.module('roi.app')
	.config(['$stateProvider', '$urlRouterProvider', '$ocLazyLoadProvider', '$breadcrumbProvider', function ($stateProvider, $urlRouterProvider, $ocLazyLoadProvider, $breadcrumbProvider) {
		$urlRouterProvider.otherwise('/dashboard');
		$ocLazyLoadProvider.config({
			// Set to true if you want to see what and when is dynamically loaded
			debug: true
		});
		$breadcrumbProvider.setOptions({
			prefixStateName: 'app.dashboard',
			includeAbstract: true,
			template: '<li class="breadcrumb-item" ng-repeat="step in steps" ng-class="{active: $last}" ng-switch="$last || !!step.abstract"><a ng-switch-when="false" href="{{step.ncyBreadcrumbLink}}">{{step.ncyBreadcrumbLabel}}</a><span ng-switch-when="true">{{step.ncyBreadcrumbLabel}}</span></li>'
		});

		$stateProvider.state('app', {
			abstract: true,
			templateUrl: 'views/common/layouts/full.html',
			//page title goes here
			ncyBreadcrumb: {
				label: 'Root',
				skip: true
			},
			controller: 'AppController',
			controllerAs: 'appCtrl',
			resolve: {
				curUser: ['SessionService',
					function ( SessionService ) {
						return SessionService.authenticate();
					}
				]
			}
		})
		/*.state('app.main', {
			url: '/dashboard',
			templateUrl: 'views/main.html',
			//page title goes here
			ncyBreadcrumb: {
				label: 'Home',
			},
			//page subtitle goes here
			params: { subtitle: 'Welcome to ROOT powerfull Bootstrap & AngularJS UI Kit' },
			resolve: {
				loadPlugin: ['$ocLazyLoad', function ($ocLazyLoad) {
					// you can lazy load files for an existing module
				}],
				loadMyCtrl: ['$ocLazyLoad', function ($ocLazyLoad) {
					// you can lazy load controllers
					return $ocLazyLoad.load({
						files: ['js/controllers/main.js']
					});
				}]
			}
		}) */
		.state('appSimple', {
			abstract: true,
			templateUrl: 'views/common/layouts/simple.html',
			resolve: {
			}
		})

		// Additional Pages
		.state('appSimple.login', {
			url: '/loginsimple',
			templateUrl: 'views/pages/login.html'
		})
		.state('appSimple.register', {
			url: '/register',
			templateUrl: 'views/pages/register.html'
		})
		.state('appSimple.404', {
			url: '/404',
			templateUrl: 'views/pages/404.html'
		})
		.state('appSimple.500', {
			url: '/500',
			templateUrl: 'views/pages/500.html'
		});
	}]);
})();