(function () {
	'use strict';
	angular.module('roi.actions')
	.config(['$stateProvider', function ($stateProvider) {
		$stateProvider.state('app.actions', {
			url: '/actions',
			abstract: true,
			ncyBreadcrumb: {
				skip: true
			},
			template: '<ui-view></ui-view>'
		});
		$stateProvider.state('app.actions.list', {
			url: '/list',
			ncyBreadcrumb: {
				label: 'Actions'
			},
			controller: 'ActionListController',
			controllerAs: 'tc',
			templateUrl: './js/modules/actions/action-list.html'
		});
		$stateProvider.state('app.actions.action', {
			url: '/action/:actionId',
			ncyBreadcrumb: {
				parent: 'app.actions.list',
				label: 'Action'
			},
			controller: 'ActionController',
			controllerAs: 'tc',
			templateUrl: './js/modules/actions/action.html'
		});
		$stateProvider.state('app.actions.new', {
			url: '/action/new',
			ncyBreadcrumb: {
				parent: 'app.actions.list',
				label: 'Add New Action'
			},
			controller: 'NewActionController',
			controllerAs: 'tc',
			templateUrl: './js/modules/actions/action-new.html'
		});
	}]);
})();